<div class="container-fluid">
    <div class="row">
        <div class="col-12 five">
            <h1>My shop products</h1>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="form-floating">
                <select class="form-select form-control-sm" id="shopName"
                        (change)="selectAllProduct($event)">
                    <option value="" disabled selected>Select Shop Name</option>
                    <option *ngFor="let shop of shopList" value="{{shop.traderId}}">{{shop.traderName}}</option>
                </select>
                    <label>Select Shop Name</label>
                </div>
            </div>
        </div>
        <div class="container-fluid p-2 mt-3">
            <div class="card container-fluid bg-white mt-3 col-md-12">
                <div class="row mt-2 mb-2">
                    <div class="col-lg-9"></div>
                    <div class="col-lg-3 col-12 float-right">
                        <div class="form-floating">
                            <input type="search" class="form-control" placeholder="search"
                                   [(ngModel)]="searchText">
                            <label class="text-gray">Search</label>
                        </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row mt-3">
                    <div class="col-md-12 table-responsive table-wrapper-scroll-y tableFixHead">
                        <table class="table text-center">
                            <thead class="header sticky-top border-0">
                            <tr>
                                <th>S.No</th>
                                <th>Product Name</th>
                                <th>MRP Price</th>
                                <th>SRP Price</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let Product of activeShopProducts | filter: searchText; let i = index;">
                                <td class="col-lg-1">{{i + 1}}</td>
                                <td class="col-lg-5">{{Product.productName}}</td>
                                <!-- <td>RS.{{Product.shopProductMRP}}</td>-->
                                <!--<td>RS.{{Product.shopProductSRP}}</td>-->
                                <td class="col-lg-2"><input type="text" style="text-align: right" class="form-control"
                                                         value="{{Product.shopProductMRP}}"
                                                         [(ngModel)]="Product.shopProductMRP"
                                                         (input)="updateButtonShow(Product)"></td>
                                <td class="col-lg-2"><input type="text" style="text-align: right" class="form-control"
                                                         value="{{Product.shopProductSRP}}"
                                                         [(ngModel)]="Product.shopProductSRP"
                                                         (input)="updateButtonShow(Product)"></td>
                                <td class="col-lg-2">
                                    <button type="button" class="btn btn-update" [disabled]="!Product.isUpdateEnabled"
                                            (click)="updateSRP(Product.shopProductSRP, Product.shopProductMRP, Product.shopProductId)">
                                        Update
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
