<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 five">
            <h1>Product exclusion</h1>
        </div>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="productExclusionDetails" id="productExclusionDetails">
        <div class="row mt-3">
            <!--<div class="col-md-3">
                <label>Shop ID<span style="color:red;">*</span></label>
                <div class="">
                    <input type="text" class="form-control" id="shopid" formControlName="shopId" readonly
                           placeholder="Shop Id">
                </div>
            </div>-->
            <div class="col-md-3">
                <label>Shop Name<span style="color:red;">*</span></label>
                <div class="">
                    <select class="form-control" formControlName="shopName" id="shopName" (change)="getShopId($event)">
                        <option value="" selected disabled>Select Shop Name</option>
                        <option *ngFor="let shop of shopList" value="{{shop.traderId}}">{{shop.traderName}}</option>
                    </select>
                    <span *ngIf="productExclusionErrors.shopname"
                          class="error-text">{{productExclusionErrors.shopname}}</span>
                </div>
            </div>
            <div class="col-md-3">
                <label>Category<span style="color:red;">*</span></label>
                <div class="">
                    <select class="form-control" id="category" formControlName="category"
                            (change)="selectCategory($event)">
                        <option value="" disabled selected hidden>Select Product Category</option>
                        <option *ngFor="let category of mainCategoryList"
                                value="{{category.catId}}">{{category.catName}}</option>
                    </select>
                    <span *ngIf="productExclusionErrors.category"
                          class="error-text">{{productExclusionErrors.category}}</span>
                </div>
            </div>
            <div class="col-md-3">
                <label>Sub Category<span style="color:red;">*</span></label>
                <div class="">
                    <select class="form-control" id="subcategory" formControlName="subcategory"
                            (change)="selectSubCategory($event)">
                        <option value="" disabled selected>Select Product Sub Category</option>
                        <option *ngFor="let sub of subCategory" value="{{sub.subCatId}}">{{sub.subCatName}}</option>
                    </select>
                    <span *ngIf="productExclusionErrors.subcategory"
                          class="error-text">{{productExclusionErrors.subcategory}}</span>
                </div>
            </div>

            <!--<div class="col-md-3">
                <label>Product Name<span style="color:red;">*</span></label>
                <div class="">
                    <ng-multiselect-dropdown
                            (onDeSelect)="onItemDeSelect($event)"
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            [(ngModel)]="productItem"
                            [data]="productList"
                            [placeholder]="'Select Product'"
                            [settings]="DropDownSettings"
                            formControlName="productId"
                    >
                    </ng-multiselect-dropdown>
                </div>
            </div>-->
        </div>
        <div class="row mt-3">
            <div class="col-6">
                <label>Click here to exclude the products</label>
                <div class="card card-design">
                    <div *ngIf="currentProductDetails.length > 0">
                        <div *ngFor="let product of currentProductDetails" class="row col-12">
                            <input class="form-check ml-2 col-1" style="align-self: start" type="checkbox" (change)="clickToRemove($event, product.productId)">
                            <span class="col-10">{{product.productName}}</span>
                        </div>
                    </div>
                    <div *ngIf="currentProductDetails.length == 0">
                        <span class="justify-content-center d-flex">No Data Found</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <label>Excluded products</label>
                <div class="card card-design">
                    <div *ngIf="removedProductDetails.length > 0">
                        <div *ngFor="let product of removedProductDetails; let i = index" class="row col-12">
                            <span class="ml-2">{{i+1}}. {{product.productName}}</span>
                        </div>
                    </div>
                    <div *ngIf="removedProductDetails.length == 0">
                        <span class="justify-content-center d-flex">No Data Found</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <div class="" style="text-align: center;">
                    <button type="submit" class="btnColor">Click to Exclude</button>
                </div>
            </div>
        </div>
    </form>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
