<!--
<div class="container-fluid col-lg-11 mt-3 vh-90" style="border: 1px solid black">
    <h2 class="font-weight-bolder text-capitalize ml-2 mt-3">{{traderName}}</h2>
    <h5 class="font-weight-bolder text-capitalize  ml-2 mt-1">GST No: {{shopGst}}</h5>
    <hr class="line">
    <div class="row">
        <div class="col-md-2">
            <label class="text-gray-dark">Order ID</label>
        </div>
        <div class="col-md-4">
            <label>{{orderUID}}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <label class="text-gray-dark">Order Date</label>
        </div>
        <div class="col-md-4">
            <label>{{orderDate}}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <label class="text-gray-dark">Shop Name</label>
        </div>
        <div class="col-md-4">
            <label>{{retailerName}}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2">
            <label class="text-gray-dark">Delivery Address</label>
        </div>
        <div class="col-md-4">
            <label>{{doorNo}}, {{streetName}}, {{area}}, {{city}}, {{state}}</label>
        </div>
    </div>
    <div class="row font-weight-light">
        <div class="col-md-2">
            <label class="text-gray-dark">Invoice Date</label>
        </div>
        <div class="col-md-4">
            <label>{{invoiceDate}}</label>
        </div>
    </div>
    <div class="row font-weight-light">
        <div class="col-md-2">
            <label class="text-gray-dark">GST No</label>
        </div>
        <div class="col-md-4">
            <label>{{retailerGST}}</label>
        </div>
    </div>
    <div class="row font-weight-light">
        <div class="col-md-2">
            <label class="text-gray-dark">Shipped Date</label>
        </div>
        <div class="col-md-4">
            <label>{{shippedDate}}</label>
        </div>
    </div>
    <hr class="line">
        <div class="col-md-12 table-responsive-md">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th class="col-md-1 text-center">S.No</th>
                        <th class="col-md-6 text-center">Item</th>
                        <th class="col-md-2 text-center">Price</th>
                        <th class="col-md-1 text-center">Quantity</th>
                        <th class="col-md-2 text-center">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of this.orderDetailSForInvoice;  let i = index;">
                        <td class="col-md-1 text-center">{{i + 1}}</td>
                        <td class="col-md-6 text-center">{{item.shopProductName}}</td>
                        <td class="col-md-2 text-center">{{item.shopProductSrp}}</td>
                        <td class="col-md-1 text-center">{{item.itemQty}}</td>
                        <td class="col-md-2 text-center">{{item.shopProductSrp * item.itemQty}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="container-fluid">
            <hr class="line">
            <div class="row mt-3">
                <div class="col-md-8"></div>
                <div class="col-md-2">
                    <p class="font-weight-bolder">SubTotal</p>
                </div>
                <div class="col-md-2">
                    <label>{{totalAmount}}</label>
                </div>
                <hr class="line">
            </div>
            <div class="row mt-3">
                <div class="col-md-9"></div>
                <div class="col-md-1">
                    <label>Total</label>
                </div>
                <div class="col-md-2">
                    <label>{{totalAmount}}</label>
                </div>
            </div>
        </div>
        <footer>
            <div class="row">
                <div class="col-md-12 text-center">
                    <label>www.mykadai.com</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <label>Thank You For Shopping With Us...</label>
                </div>
            </div>
        </footer>
</div>-->
<!--
<div class="invoice-box">
    <table cellpadding="0" cellspacing="0">
        <tr class="top">
            <td colspan="2">
                <table>
                    <tr>
                        <td class="title">
                            &lt;!&ndash;<img
                                    src="https://sparksuite.github.io/simple-html-invoice-template/images/logo.png"
                                    style="width: 100%; max-width: 300px"
                            />&ndash;&gt;
                            {{traderName}}
                        </td>

                        <td>
                            Order ID: {{orderUID}}<br />
                            Order Date: {{orderDate}}<br />
                            Invoice Date: {{invoiceDate}}
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

        <tr class="information">
            <td colspan="2">
                <table>
                    <tr>
                        <td>
                           {{traderName}}<br />
                            {{shopGst}}<br/>
                            {{doorNo}}, {{streetName}}, {{area}}<br />
                            {{city}}, {{state}}
                        </td>

                        <td>
                            {{retailerName}}<br />
                            {{retailerGST}}<br />
                            Delivery Date: {{shippedDate}}
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr class="heading">
            <td class="col-md-1 text-center">S.No</td>
            <td class="col-md-6 text-center">Item</td>
            <td class="col-md-2 text-center">Price</td>
            <td class="col-md-1 text-center">Quantity</td>
            <td class="col-md-2 text-center">Total</td>
        </tr>

        <tr class="item" *ngFor="let item of this.orderDetailSForInvoice;  let i = index;">
            <td class="text-center">{{i + 1}}</td>
            <td class="text-center">{{item.shopProductName}}</td>
            <td class="text-center">{{item.shopProductSrp | currency:'INR':''}}</td>
            <td class="text-center">{{item.itemQty}}</td>
            <td class="text-center">{{item.shopProductSrp * item.itemQty | currency:'INR':''}}</td>
        </tr>
    </table>
</div>-->
<!--
<div class="container-fluid">
<div class="col-10 ml-1" style="border: 1px solid black; width: 98%; margin-top: 10px">
    <div class="row" style="border-bottom: 1px solid black">
        <div class="col-12">
            <h6 class="text-bold text-center">Tax Invoice</h6>
            <h6 class="text-bold text-center">Ananda Enterprises</h6>
            <p class="text-center">Dep No: 68 T.N.F.G Marketing Yard, Sikkander Chavadi, Madurai - 625018, Ph: 9843225007 & 2666054</p>
            <p class="text-center">anandaenterprises20@gmail.com</p>
            <p class="text-center">GSTIN/UIN : 33AMJPM5206H227</p>
        </div>
    </div>
    <div class="row">
        <div class="col-8" style="border-right: 1px solid black">
            <p class="text-bold">Buyer</p>
            <h6 class="text-bold">Harshini Rice Point</h6>
            <p>156 N A & Sons Complex, Theni Main Road, HMS Colony, Madurai</p>
            <p>Ph: 861039</p>
            <p>GSTIN/UIN : 33FCEPP9745J1Z1</p>
        </div>
        <div class="col-4">
            <div class="row mt-2 justify-content-center align-items-center">
                <div class="col-6" style="border-right: 1px solid black">
                    <p>Bill No</p>
                </div>
                <div class="col-6">
                    <p>RT/282</p>
                </div>
            </div>
            <div class="row mt-2 justify-content-center align-items-center" style="border-top: 1px solid black">
                <div class="col-6" style="border-right: 1px solid black">
                    <p>Invoice Date</p>
                </div>
                <div class="col-6">
                    <p>26/12/2023</p>
                </div>
            </div>
            <div class="row justify-content-center align-items-center" style="border-top: 1px solid black">
                <div class="col-6" style="border-right: 1px solid black">
                    <p>Order Date</p>
                </div>
                <div class="col-6">
                    <p>25/12/2023</p>
                </div>
            </div>
            <div class="row justify-content-center align-items-center" style="border-top: 1px solid black">
                <div class="col-6" style="border-right: 1px solid black">
                    <p>Delivery Date</p>
                </div>
                <div class="col-6">
                    <p>27/12/2023</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-2"></div>
</div>-->
<div class="container-fluid">
    <div class="col-10 mt-5">
        <table class="mt-3">
            <tr>
                <th class="col-10 text-center" style="border-right: 1px solid transparent">
                    <h5 class="text-bold">Tax Invoice</h5>
                    <h3 class="text-bold mb-1" style="font-family: openSans;margin-top: -14px">Ananda Enterprises</h3>
                    <p style="font-size: 17px;margin-top: -8px">Dep No: 68 T.N.F.G Marketing Yard, Sikkander Chavadi, Madurai - 625018</p>
                    <h6 class="text-bold" style="font-size: 18px;margin-top: -14px">Ph: 9843225007 & 2666054</h6>
                    <p style="font-size: 14px;margin-top: -12px">anandaenterprises20@gmail.com</p>
                    <p class="" style="margin-top: -15px">GSTIN/UIN : {{shopGst}}</p>
                </th>
                <th class="col-2"></th>
            </tr>
        </table>
        <table style="margin-top: 2px">
                <tr>
                    <th class="ml-2" style="border-top: 1px solid transparent;padding: 10px">
                        <p class="text-bold" style="margin-top: -10px">Buyer</p>
                        <h6 class="text-bold" style="margin-top: -14px">{{retailerName}}</h6>
                        <p class="text-capitalize" style="margin-top: -6px">{{doorNo}}, {{streetName}}, {{area}}, {{city}}, {{state}}</p>
                        <p style="margin-top: -15px">GSTIN/UIN : {{retailerGST}}</p>
                    </th>
                    <th style="border-top: 1px solid transparent;padding: 10px">
                        <p style="margin-top: -5px">Bill No</p>
                        <p style="margin-top: -15px">Order Date</p>
                        <p style="margin-top: -15px">Invoice Date</p>
                        <p style="margin-top: -15px">Delivery Date</p>
                    </th>
                    <th style="border-top: 1px solid transparent;padding: 10px">
                        <p style="margin-top: -5px">RT/282</p>
                        <p style="margin-top: -15px">{{orderDate}}</p>
                        <p style="margin-top: -15px">{{invoiceDate}}</p>
                        <p style="margin-top: -15px">{{shippedDate}}</p>
                    </th>
                </tr>
        </table>
        <table class="table-sm" style="height: 530px">
            <tr class="text-center h5" style="height: 10px">
                <th style="border-top: 1px solid transparent">S.No</th>
                <th style="border-top: 1px solid transparent">Description</th>
                <th style="border-top: 1px solid transparent">Tax</th>
                <th style="border-top: 1px solid transparent">Net Rate</th>
                <th style="border-top: 1px solid transparent">Qty</th>
                <th style="border-top: 1px solid transparent">Rate</th>
                <th style="border-top: 1px solid transparent">Amount</th>
            </tr>
            <tr class="item" *ngFor="let item of this.orderDetailSForInvoice;  let i = index;" style="height: 10px">
                <td class="text-center col-1">{{i + 1}}</td>
                <td class="text-center col-4 h6" style="font-weight: 700">{{item.shopProductName}}</td>
                <td class="text-center col-1">{{item.cgst + item.sgst}} %</td>
                <!--<td class="text-right col-2">{{item.shopProductSrp * 0.5 + item.shopProductSrp | currency:'INR':''}}</td>-->
                <td class="text-right col-2">{{item.shopProductSrp + item.shopProductSrp * (item.cgst + item.sgst) / 100 | currency:'INR':''}}</td>
                <td class="text-right col-1">{{item.itemQty}}</td>
                <td class="text-right col-2">{{item.shopProductSrp | currency:'INR':''}}</td>
                <td class="text-right col-2 h6" style="font-weight: 700">{{item.shopProductSrp * item.itemQty | currency:'INR':''}}</td>
            </tr>
            <tr class="text-center mb-0">
                <td></td>
                <td>
                    <p class="text-bold">Sub Total</p>
                    <p>CGST%</p>
                    <p>SGST%</p>
                    <p *ngIf="roundoffShow">Round Off</p>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                    <p class="text-bold text-right">{{totalAmount | currency:'INR':''}}</p>
                    <p class="text-bold text-right">{{cGstAmount | currency:'INR':''}}</p>
                    <p class="text-bold text-right">{{sGstAmount | currency:'INR':''}}</p>
                    <p class="text-bold text-right" *ngIf="roundoffShow">- {{roundedDiscount | currency:'INR':''}}</p>
                </td>
            </tr>
            <tr class="text-center" style="height: 10px">
                <td>E.&.O.E.</td>
                <td class="text-bold">Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right font-weight-bolder h5">{{totalAmount + cGstAmount + sGstAmount | currency:'INR':''}}</td>
            </tr>
        </table>
        <table>
            <tr style="border-top: 1px solid transparent;padding: 10px">
                <th style="border-top: 1px solid transparent;border-right: 1px solid transparent;padding: 10px">
                    <h5 class="text-bold">Bank Details</h5>
                    <h6 class="text-bold">A/c No: 510909010211337, Bank Name: City Union Bank, IFSC Code: CIUB0000548,Branch : Koodalnagar</h6>
                </th>
            </tr>
            <tr>
                <th class="col-7" style="border-top: 1px solid transparent;padding: 10px">
                    <h4 class="text-bold">Terms & Conditions</h4>
                    <p class="text-bold">Goods once sold cannot be token back.</p>
                    <p class="text-bold">Subject to Jurisdiction</p>
                </th>
                <th class="col-5">
                    <h4 class="text-bold text-center mb-5">For Ananda Enterprises</h4>
                    <p class="text-bold text-center mt-3"></p>
                    <p class="text-center">Signature</p>
                </th>
            </tr>
        </table>
    </div>
    <div class="col-2"></div>
</div>