import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public user;

    constructor(private appService: AppService, private spinner: NgxSpinnerService, private router: Router,) {}

    ngOnInit(): void {
        // this.user = this.appService.user;
        this.user = localStorage.getItem('userName');
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/login']);
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
