import {allShopProductDetails, allShopProductDetailsData} from "@pages/modal/allShopProductDetails";
import {productDetails, productDetailsData} from "@pages/modal/productdetails";
import {mainCategory} from "@pages/modal/mainCategory";
import {SubCategory} from "@pages/modal/subCategory";

export interface AddProductDto {
    shopProduct: allShopProductDetails;
    products: productDetails;
    mainCategory: mainCategory;
    subCategory: SubCategory;
}

export class AddProductDataDto {
    shopProduct: allShopProductDetailsData;
    products: productDetailsData;
}