import {Component, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "@/store/state";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {shopDetails} from "@pages/modal/shopdetails";
import {mainCategory} from '@pages/modal/mainCategory';
import {SubCategory} from '@pages/modal/subCategory';
import {productDetails, productDetailsData} from '@pages/modal/productdetails';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {data} from "browserslist";
import {AddProductDataDto, AddProductDto} from "@pages/modal/addProductDto";
import {allShopProductDetailsData} from "@pages/modal/allShopProductDetails";
import {add} from "husky/lib";

@Component({
    selector: 'product-inclusion',
    templateUrl: './inclusion.component.html',
    styleUrls: ['./inclusion.component.scss']
})
export class productInclusion implements OnInit {
    newProductDetails: productDetails | undefined;
    destroy$: Subject<boolean> = new Subject<boolean>();
    images: String = "";
    shopList: shopDetails[] = [];
   userShopList: shopDetails[] = [];
    subCategoryList: String[] = [];
    categoryList: String[] = [];
    mainCategoryList: mainCategory [] = [];
    subCategory: SubCategory[] = [];
    productName: productDetails[] = [];
    productList: String[] = [];
    showShopName: String[] = [];
    showShopType: String[] = [];
    fileList: File[] = [];
    listOfFiles: any[] = [];
    fileUploadStatus: string = "Exist";
    shopType: String[] = [];
    formData = new FormData();
    isSquare = false;
    isCircle = false;
    isRectangle = false;
    traderId: number = 0;
    distributorId: number = 0;
    categoryId: number;
    subCategoryId: number;

    constructor(private renderer: Renderer2, private store: Store<AppState>,
                private appService: AppService, private router: Router,
                private spinner: NgxSpinnerService, private toaster: ToastrService) {
    }

    productInclusionDetails = new UntypedFormGroup({
        shop: new UntypedFormControl("", Validators.required),
        shopType: new UntypedFormControl("", Validators.required),
        productName: new UntypedFormControl("", Validators.required),
        subProductCategory: new UntypedFormControl("", Validators.required),
        mainProductCategory: new UntypedFormControl("", Validators.required),
        productQuantity: new UntypedFormControl("", Validators.required),
        productUOM: new UntypedFormControl("", Validators.required),
        fromDate: new UntypedFormControl("", Validators.required),
        toDate: new UntypedFormControl("", Validators.required),
        manufactureCountry: new UntypedFormControl("", Validators.required),
        productImage: new UntypedFormControl("", Validators.required),
        addedBy: new UntypedFormControl("", Validators.required),
        shopId: new UntypedFormControl("", Validators.required),
        productMRP: new UntypedFormControl("", Validators.required),
        productSRP: new UntypedFormControl("", Validators.required),
        productTax: new UntypedFormControl("", Validators.required),
        productDiscountType: new UntypedFormControl("", Validators.required),
        minOrderQty: new UntypedFormControl("", Validators.required),
        maxOrderQty: new UntypedFormControl("", Validators.required),
        enabled: new UntypedFormControl('', Validators.required),
        shopProductMRP: new UntypedFormControl('', Validators.required),
        shopProductSRP: new UntypedFormControl('', Validators.required),
        shopProductTax: new UntypedFormControl('', Validators.required),
        sgst: new UntypedFormControl('', Validators.required),
        cgst: new UntypedFormControl('', Validators.required),
        shopProductDiscountType: new UntypedFormControl('', Validators.required),
        shopMinOrderQty: new UntypedFormControl('', Validators.required),
        shopMaxOrderQty: new UntypedFormControl('', Validators.required),
        status: new UntypedFormControl('', Validators.required),
        products: new UntypedFormControl('', Validators.required),
        distributorId: new UntypedFormControl('', Validators.required),
        traderId: new UntypedFormControl('', Validators.required),
    });

    public productInclusionErrors = {
        shopName: "",
        shopType: "",
        productName: "",
        brandName: "",
        subCategory: "",
        productCategory: "",
        shopProductMRP: "",
        shopProductSRP: "",
        sgst: "",
        cgst: "",
        productQuantity: "",
        productUom: "",
        fromDate: "",
        toDate: "",
        manufactureCountry: "",
        productImage: "",
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.productInclusionErrors.shopName = "";
        this.productInclusionErrors.shopType = "";
        this.productInclusionErrors.productCategory = "";
        this.productInclusionErrors.subCategory = "";
        this.productInclusionErrors.productName = "";
        this.productInclusionErrors.brandName = "";
        this.productInclusionErrors.shopProductMRP = "";
        this.productInclusionErrors.shopProductSRP = "";
        this.productInclusionErrors.productQuantity = "";
        this.productInclusionErrors.productUom = "";
        this.productInclusionErrors.fromDate = "";
        this.productInclusionErrors.toDate = "";
        this.productInclusionErrors.manufactureCountry = "";
        this.productInclusionErrors.productImage = "";
        this.productInclusionErrors.sgst = "";
        this.productInclusionErrors.cgst = "";
        let hasError = false;

        if (this.productInclusionDetails.get("shopId")?.invalid) {
            this.productInclusionErrors.shopName = "*Shop Name is Required";
            hasError = true;
        }
        /*if (this.productInclusionDetails.get("shopType")?.invalid) {
            this.productInclusionErrors.shopType = "*Shop Type is Required";
            hasError = true;
        }*/
        if (this.productInclusionDetails.get("mainProductCategory")?.invalid) {
            this.productInclusionErrors.productCategory = "*Product Category is Required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("subProductCategory")?.invalid) {
            this.productInclusionErrors.subCategory = "*Sub Category is Required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("productName")?.invalid) {
            this.productInclusionErrors.productName = "*Product Name is Required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("shopProductMRP")?.invalid) {
            this.productInclusionErrors.shopProductMRP = "*MRP Price is Required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("shopProductSRP")?.invalid) {
            this.productInclusionErrors.shopProductSRP = "*Sale Price is Required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("sgst")?.invalid) {
            this.productInclusionErrors.sgst = "*Product SGST is required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("cgst")?.invalid) {
            this.productInclusionErrors.cgst = "*Product CGST is required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("productQuantity")?.invalid) {
            this.productInclusionErrors.productQuantity = "*Product Quantity is Required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("productUOM")?.invalid) {
            this.productInclusionErrors.productUom = "*Product UOM is Required";
            hasError = true;
        }
        if (this.productInclusionDetails.get("manufactureCountry")?.invalid) {
            this.productInclusionErrors.manufactureCountry = "*Manufacturer Country  is Required";
            hasError = true;
        }

        if (!hasError) {
            this.addProduct();
        }
    }

    onShapeChanged(shape: string) {
        if (shape == "square") {
            this.isSquare = true;
            this.isCircle = false;
            this.isRectangle = false;
        } else if (shape == "rectangle") {
            this.isSquare = false;
            this.isCircle = false;
            this.isRectangle = true;
        } else if (shape == "circle") {
            this.isSquare = false;
            this.isCircle = true;
            this.isRectangle = false;
        }
    }

    // Only Integer Enter functionality
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    getProduct() {
        var catList: String[] = [];
        this.spinner.show();
        this.appService
            .getCategory()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Product values::", data);
                this.mainCategoryList = data;
                this.spinner.hide();
                for (let category of this.mainCategoryList) {
                    catList.push(category.catName);
                }
                catList.sort();
                this.categoryList = catList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            }, (error: any) => {
                this.spinner.hide();
                this.toaster.error("Something went wrong for get products")
            });
    }

    //Main Category Functionality
    selectCategory(event) {
        const value = event.target.value;
        this.categoryId = value;
        console.log("CatId::",  this.categoryId)
        var catId: Number[] = [];
        var subCategory: String [] = [];
        if (value != "") {
            for (var id of this.mainCategoryList) {
                if (value == id.catId) {
                    catId.push(id.catId)
                    this.spinner.show();
                    this.appService
                        .getSubCatID(catId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((data) => {
                            console.log("subCategory Details ::", data);
                            // @ts-ignore
                            this.subCategory = data;
                            this.spinner.hide();
                            for (let subCat of this.subCategory) {
                                subCategory.push(subCat.subCatName);
                            }
                            subCategory.sort();
                            this.subCategoryList = subCategory.filter(function (elem, index, self) {
                                return index === self.indexOf(elem);
                            });
                        });
                }
            }
        }
    }

    selectSubCategory(event) {
        const value =  event.target.value;
        this.subCategoryId = value;
        console.log("sub catId::", this.subCategoryId)
    }

    addProduct() {
        Swal.fire({
            title: "Are you Sure to Add The Product In Your Shop?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes,Add Now",
        }).then((result) => {
            if (result.isConfirmed) {
                this.productInclusionDetails.controls['traderId'].setValue(this.traderId);
                this.productInclusionDetails.controls['distributorId'].setValue(this.distributorId);
              /*  let addNewProduct = new AddProductDataDto();*/

                let addNewProduct: AddProductDataDto = new AddProductDataDto();

                let shopProduct = new allShopProductDetailsData();
                shopProduct.traderId = this.traderId;
                console.log("tId::", shopProduct.traderId)
                shopProduct.distributorId = this.distributorId;
                shopProduct.status = "Active";
                console.log("dId::", shopProduct.distributorId)
                shopProduct.shopProductMRP = Number(this.productInclusionDetails.get("shopProductMRP").value);
                shopProduct.shopProductSRP = Number(this.productInclusionDetails.get("shopProductSRP").value);
                shopProduct.mainProductCategoryId = this.categoryId;
                shopProduct.subProductCategoryId = this.subCategoryId;
                shopProduct.appCode = localStorage.getItem('appCode');
                shopProduct.productName = (this.productInclusionDetails.get("productName").value);
                shopProduct.cgst = (this.productInclusionDetails.get("cgst").value);
                shopProduct.sgst = (this.productInclusionDetails.get("sgst").value);
                console.log("cid::", shopProduct.mainProductCategoryId);
                console.log("sub id::", shopProduct.subProductCategoryId);
                console.log("addNewProduct::",  shopProduct.shopProductMRP);


                let product = new productDetailsData();
                product.mainProductCategory = Number(this.productInclusionDetails.get("mainProductCategory").value);
                product.subProductCategory = Number(this.productInclusionDetails.get("subProductCategory").value);
                product.productName = (this.productInclusionDetails.get("productName").value);

                addNewProduct.shopProduct = shopProduct;
                console.log("trader id new::", addNewProduct.shopProduct.traderId)
                addNewProduct.products = product;
                this.spinner.show();
                this.appService
                    .saveNewProductInclusion(addNewProduct)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data)=> {
                        if (this.fileList.length > 0) {
                            this.uploadIncludeProductImage(data.products.productId)
                        }
                        this.spinner.hide();
                        this.productInclusionDetails.reset();
                        this.toaster.success("New Product added")
                        console.log("product Inclusion::", data);
                    },(error: any) => {
                        this.spinner.hide();
                        this.toaster.error("something went wrong");
                    })
            }
        })
    }

    uploadIncludeProductImage(productId) {
        const formData = new FormData();
        for (const file of this.fileList) {
            formData.append("file", file, file.name)
        }
        this.spinner.show();
        this.appService.productImageUpload(formData, productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.spinner.hide();
                this.toaster.success("Image uploaded successfully");
            }, (err: any) => {
                this.spinner.hide();
                console.log("Something went wrong, Please try again");
                this.toaster.error("Something went wrong, Please try again later")
            })
        this.fileList = [];
    }

    ngOnInit(): void {
        this.getProduct();
        this.getShopDetails();
    }

    // Image upload functionality and show in preview card
    onFileChange(event: any) {
        this.fileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            console.log("select file size ::" + selectedFile);
            var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);
            if (fileSizeInMb <= 10) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.images = event.target.result;
                        this.productInclusionDetails.patchValue({});
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.fileList.push(selectedFile);
                this.listOfFiles.push(selectedFile.name);
                this.fileUploadStatus = "Uploaded";
                console.log("File list::", this.fileList)
            } else {
                this.toaster.error("File size is large. Please upload below 10MB")
            }
        }


    }

    //shop type show functionality
    getShopName(event) {
        const value = event.target.value;
        /*       var shopType: String[] = [];
               for (let shop of this.userShopList) {
                   console.log("value::", value)
                   console.log("shop name::", shop.shopId)
                   if (value != "") {
                       if (value == shop.shopId) {
                           shopType.push(shop.shopType)
                           console.log("shop type::", shopType)
                       }
                   }
                   this.shopType = shopType;
                   this.productInclusionDetails.controls['shopType'].setValue(this.shopType)
               }*/
        this.traderId = value;
        console.log("tarderId::", this.traderId)
        this.distributorId = Number(localStorage.getItem("distributorId"));
        console.log("dis ID::", this.distributorId)
    }

    getShopDetails() {
        this.appService.getShopDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    console.log("Shop Details::", data);
                    // @ts-ignore
                    this.shopList = data;
                },
                (error: any) => {
                    this.spinner.hide();
                    this.toaster.error("Something went wrong for get Shop Details")
                })
    }

    //User ID based Shop Name show in the field
    /*getShopDetails() {
        this.spinner.show();
        this.appService.getShopDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Get Shop Details", data)
                // @ts-ignore
                this.shopList = data;
                this.spinner.hide();
                var shopList: shopDetails[] = [];
                for (var shop of this.shopList) {
                    const userId = localStorage.getItem('userId')
                    if (Number(userId) == shop.createdBy) {
                        shopList.push(shop)
                        console.log("Shop List::", shopList)
                    }
                    this.userShopList = shopList;
                    var shopName: String[] = [];
                    // var shopType: String[] = [];
                    for (var name of shopList) {
                        shopName.push(name.shopName)
                        // shopType.push(name.shopType)
                    }
                    this.showShopName = shopName;
                    // this.showShopType = shopType;
                }
            }, (error: any) => {
                this.spinner.hide();
                this.toaster.error("Something went wrong for get shop details")
            })
    }*/

    //add a product functionality and alert popup show by sweetalert2
    /* addProduct() {
         Swal.fire({
             title: "Are you Sure to Add The Product In Your Shop?",
             icon: "info",
             showCancelButton: true,
             confirmButtonColor: "#3085d6",
             cancelButtonColor: "#d33",
             confirmButtonText: "Yes,Add Now",
         }).then((result) => {
             if (result.isConfirmed) {
                /!* for (const file of this.fileList) {
                     this.formData.append('file', file, file.name);
                 }*!/
                 // const SRP = this.productInclusionDetails.controls["productMRP"].value
                 // this.productInclusionDetails.controls["productSRP"].setValue(SRP);
                 this.productInclusionDetails.controls["addedBy"].setValue("Shop");
                 console.log("Form Group:", this.productInclusionDetails.value)
                 this.spinner.show();
                 this.appService
                     .saveNewProductInclusion(this.productInclusionDetails.value)
                     .pipe(takeUntil(this.destroy$))
                     .subscribe(
                         (data) => {
                             //@ts-ignore
                             var shopData: productDetails = data;
                             /!*this.appService.productImageUpload(this.formData, shopData.productId)
                                 .pipe(takeUntil(this.destroy$))
                                 .subscribe(data => {
                                     console.log("Logo file upload");
                                 }, (error: any) => {
                                     console.log("Something went wrong. Logo File not upload")
                                 })*!/
                             setTimeout(() => {
                             }, 2000);
                             console.log("Product Details::", data);
                             const productId: number[] = [];
                             // @ts-ignore
                             this.newProductDetails = data;
                             this.productInclusionDetails.controls["enabled"].setValue("true")
                             this.productInclusionDetails.controls["shopProductMRP"].setValue(this.newProductDetails.productMRP)
                             this.productInclusionDetails.controls["products"].setValue(this.newProductDetails.productId)
                             this.productInclusionDetails.controls["shopProductSRP"].setValue(this.newProductDetails.productSRP)
                             this.productInclusionDetails.controls["shopProductTax"].setValue(this.newProductDetails.productTax)
                             this.productInclusionDetails.controls["shopProductDiscountType"].setValue(this.newProductDetails.productDiscountType)
                             this.productInclusionDetails.controls["shopMinOrderQty"].setValue(this.newProductDetails.minOrderQty)
                             this.productInclusionDetails.controls["shopMaxOrderQty"].setValue(this.newProductDetails.maxOrderQty)
                             this.productInclusionDetails.controls["status"].setValue("Active")
                             this.productInclusionDetails.controls["mainProductCategory"].setValue(this.newProductDetails.mainProductCategory)
                             this.productInclusionDetails.controls["subProductCategory"].setValue(this.newProductDetails.subProductCategory)
                             this.productInclusionDetails.controls["shopId"].setValue(this.newProductDetails.shopId)
                             this.productInclusionDetails.controls["productName"].setValue(this.newProductDetails.productName)
                             this.appService.saveNewProduct(this.productInclusionDetails.value)
                                 .pipe(takeUntil(this.destroy$))
                                 .subscribe((data) => {
                                         console.log("Saved Product Data::", data);
                                         Swal.fire("Successfully", "Your Product has been added in Your Shop.", "success")
                                         this.router.navigate(["/main/my-shop-products"]);
                                         this.spinner.hide();
                                     }, (err: any) => {
                                         this.spinner.hide();
                                         Swal.fire('Product Not Saved Please try again...!', '', 'error')
                                     },
                                 )
                         },
                         (err: any) => {

                             Swal.fire('Product Not Saved Please try again...!', '', 'error')
                         },
                         () => console.log("HTTP request completed.")
                     );


             }
         });
     }*/

}
