import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import Swal from "sweetalert2";
@Component({
    selector: 'shopapproval',
    templateUrl: './shopapproval.component.html',
    styleUrls: ['./shopapproval.component.scss']
})
export class ShopApproval implements OnInit{

    constructor (
        private router : Router
    ) {

    }
    ngOnInit(): void {
       this.successShow();
    }
    successShow() {
        Swal.fire({
          title: "Shop Details Registered Successfully...",
          icon: "success",
          confirmButtonColor: "#80D194",
          cancelButtonColor: "#e64951",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) =>{
          if(result.isConfirmed) {
            this.NavProductExclusionscreen();
          }
        });
       }

       NavProductExclusionscreen() {
        this.router.navigate(['exclusion'])
      }
}