<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <table class="table table-borderless">
            <tr>
               <td style="width:15%;"><img src="../assets/img/myKadaiLogo.png" style="height:60px;width:60px;" ></td>
               <td style=" vertical-align: middle">
                  <span>
                     <h3 class="mb-0" style="text-align: center;font-weight: bold;">MYKADAI.COM</h3>
                  </span>
               </td>
            </tr>
         </table>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Register a new membership</p>

        <form [formGroup]="registerForm" (ngSubmit)="registerByAuth()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="retypePassword"
                    type="password"
                    class="form-control"
                    placeholder="Retype password"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="icheck-primary">
                        <input
                            type="checkbox"
                            id="agreeTerms"
                            name="terms"
                            value="agree"
                        />
                        <label for="agreeTerms">
                            I agree to the <a href="#">terms</a>
                        </label>
                    </div>
                </div>
                <!-- /.col -->
                <div class="col-5">
                    <pf-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                    >
                        Register
                    </pf-button>
                </div>
                <!-- /.col -->
            </div>
        </form>

        <!-- <div class="social-auth-links text-center">
            <pf-button
                class="mb-2"
                [block]="true"
                [loading]="isFacebookLoading"
                [disabled]="isAuthLoading || isGoogleLoading"
                (click)="registerByFacebook()"
            >
                <i class="fab fa-facebook mr-2"></i>
                Sign up using Facebook
            </pf-button>
            <pf-button
                [block]="true"
                [loading]="isGoogleLoading"
                [disabled]="isAuthLoading || isFacebookLoading"
                (click)="registerByGoogle()"
                theme="danger"
            >
                <i class="fab fa-google mr-2"></i>
                Sign up using Google+
            </pf-button>
        </div> -->

        <a [routerLink]="['/login']" class="text-center">
            I already have a membership</a
        >
    </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
