import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {UserDetails} from "@pages/modal/userDetails";
import {LoginRequest} from "@services/loginRequest";
import {LoginResponse} from "@services/loginResponse";
import {NgxSpinnerService} from "ngx-spinner";
import {DistributorDetails} from "@pages/modal/distributorDetails";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public loginForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    public userDetails: LoginResponse;
    public loginUser: UserDetails | undefined;
    destroy$: Subject<boolean> = new Subject<boolean>();
    hasError: boolean;
    userName: string;
    password: string;
    rememberMe: boolean = false;
    isPasswordVisible: boolean = false;
    distributorDetails: DistributorDetails | undefined;

    constructor(
        private renderer: Renderer2, private toastr: ToastrService,
        private appService: AppService, private router: Router,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            this.rememberMe = true;
        }
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.loginForm = new UntypedFormGroup({
            userName: new UntypedFormControl(null, Validators.required),
            password: new UntypedFormControl("", [
                Validators.required,
                Validators.nullValidator, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
            ]),
        });
    }

    public loginErrors = {
        userName: "",
        password: ""
    }

    formValidation() {
        this.loginErrors.userName = "";
        this.loginErrors.password = "";
        let hasError = false;

        if (this.loginForm.get("userName")?.invalid) {
            this.loginErrors.userName = "Please Enter Your User Name";
            hasError = true;
        }
        if (this.loginForm.get("password")?.invalid) {
            if (this.loginForm.get('password').errors.required) {
                this.loginErrors.password = "Please Enter Your Password";
                hasError = true;
            }
        }

        if (!hasError) {
            this.loginAuth();
        }
    }

    onSubmit() {
        this.formValidation();
    }

    togglePasswordVisibility() {
        const passwordShow = document.getElementById('password') as HTMLInputElement;
        if (passwordShow.type == 'password') {
            passwordShow.type = 'text';
        } else {
            passwordShow.type = 'password';
        }
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    async loginByAuth() {
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            await this.appService.loginByAuth(this.loginForm.value);
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Form is not valid!');
        }
    }

    loginAuth() {
        this.spinner.show();
        const username = this.loginForm.get("userName").value;
        const password = this.loginForm.get("password").value;
        let loginRequest = new LoginRequest()
        loginRequest.userName = username
        loginRequest.password = password
        this.appService.login(loginRequest)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    if (data != null) {
                        this.userDetails = data;
                        localStorage.setItem('authToken', String(this.userDetails.authHeader))
                        localStorage.setItem('role', String(this.userDetails.userMaster.role))
                        localStorage.setItem('userType', String(this.userDetails.userMaster.userType))
                        localStorage.setItem('userName', String(this.userDetails.userMaster.userName))
                        localStorage.setItem('userId', String(this.userDetails.userMaster.userID))
                        localStorage.setItem('appCode', this.userDetails.userMaster.appCode);
                        this.getDistributorID();
                    }
                },
                (err: any) => {
                    this.spinner.hide();
                    this.toastr.error("User Name or Password is Wrong")
                })
    }

    getDistributorID() {
        this.appService
            .getDistributorId()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.distributorDetails = data;
                localStorage.setItem("distributorId", String(this.distributorDetails.distributorId));
                if (this.userDetails.userMaster.appCode == "ANANDHAM") {
                    this.router.navigate(["/main/my-shop-products"]);
                } else {
                    this.router.navigate(["/main/shop-register"]);
                }
                this.spinner.hide();
                this.toastr.success("Login Successfully");
            }, (err: any) => {
                this.spinner.hide();
                console.log("something went wrong")
            });
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }

}
