<a  class="brand-link bg-light">
  <!-- <pf-image
     alt="AdminLTE Logo"
     class="brand-image"
     height="45"
     rounded
     src="../assets/img/mklogo.png"
     style="opacity: 0.8"
     width="45"
     ></pf-image> -->
     <img src="./assets/img/mykadainew.png" alt="" class="img-fluid" style="height: 50px;width: 50px;">
  <!-- <h4 style="color: rgb(255, 255, 255);font-weight: 600;">MYKADAI.COM</h4> -->
<!--  <span style="color: rgb(255, 255, 255);font-weight: 600; margin-left: 10px;">MYKADAI.COM</span>-->
</a>
<!-- Sidebar -->
<div class="sidebar">
  <ul class="label-text mt-5" nz-menu nzMode="inline" style="margin-left: -30px">
     <ng-container>
        <!-- Sidebar Menu -->
        <nav class="mt-3" style="overflow-y: hidden; overflow-x: hidden;">
           <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
              >
               <ng-container *ngFor="let item of menu">
                   <app-menu-item
                           [menuItem]="item"
                           *ngIf="item.name != 'My Shop Products' || this.role == 'USER'"
                   ></app-menu-item>
               </ng-container>
           </ul>
        </nav>
     </ng-container>
  </ul>
</div>