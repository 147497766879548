<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
     <div class="row mb-2">
        <div class="col-sm-6">
           <h1>Blank Page</h1>
        </div>
        <div class="col-sm-6">
           <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Blank Page</li>
           </ol>
        </div>
     </div>
  </div>
  <!-- /.container-fluid -->
</section>
<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
     <div class="row">
        <!-- left column -->
        <div class="col-md-6">
           <!-- general form elements -->
           <div class="card card-primary">
              <div class="card-header">
                 <h3 class="card-title">Quick Example</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form>
                 <div class="card-body">
                    <div class="form-group">
                       <label for="exampleInputEmail1">Email address</label>
                       <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
                    </div>
                    <div class="form-group">
                       <label for="exampleInputPassword1">Password</label>
                       <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
                    </div>
                    <div class="form-group">
                       <label for="exampleInputFile">File input</label>
                       <div class="input-group">
                          <div class="custom-file">
                             <input type="file" class="custom-file-input" id="exampleInputFile">
                             <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                          </div>
                          <div class="input-group-append">
                             <span class="input-group-text">Upload</span>
                          </div>
                       </div>
                    </div>
                    <div class="form-check">
                       <input type="checkbox" class="form-check-input" id="exampleCheck1">
                       <label class="form-check-label" for="exampleCheck1">Check me out</label>
                    </div>
                 </div>
                 <!-- /.card-body -->
                 <div class="card-footer">
                    <button type="submit" class="btn btn-primary">Submit</button>
                 </div>
              </form>
           </div>
           <!-- /.card -->
           <!-- general form elements -->
           <div class="card card-primary">
              <div class="card-header">
                 <h3 class="card-title">Different Styles</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                 <h4>Input</h4>
                 <div class="form-group">
                    <label for="exampleInputBorder">Bottom Border only <code>.form-control-border</code></label>
                    <input type="text" class="form-control form-control-border" id="exampleInputBorder" placeholder=".form-control-border">
                 </div>
                 <div class="form-group">
                    <label for="exampleInputBorderWidth2">Bottom Border only 2px Border <code>.form-control-border.border-width-2</code></label>
                    <input type="text" class="form-control form-control-border border-width-2" id="exampleInputBorderWidth2" placeholder=".form-control-border.border-width-2">
                 </div>
                 <div class="form-group">
                    <label for="exampleInputRounded0">Flat <code>.rounded-0</code></label>
                    <input type="text" class="form-control rounded-0" id="exampleInputRounded0" placeholder=".rounded-0">
                 </div>
                 <h4>Custom Select</h4>
                 <div class="form-group">
                    <label for="exampleSelectBorder">Bottom Border only <code>.form-control-border</code></label>
                    <select class="custom-select form-control-border" id="exampleSelectBorder">
                       <option>Value 1</option>
                       <option>Value 2</option>
                       <option>Value 3</option>
                    </select>
                 </div>
                 <div class="form-group">
                    <label for="exampleSelectBorderWidth2">Bottom Border only <code>.form-control-border.border-width-2</code></label>
                    <select class="custom-select form-control-border border-width-2" id="exampleSelectBorderWidth2">
                       <option>Value 1</option>
                       <option>Value 2</option>
                       <option>Value 3</option>
                    </select>
                 </div>
                 <div class="form-group">
                    <label for="exampleSelectRounded0">Flat <code>.rounded-0</code></label>
                    <select class="custom-select rounded-0" id="exampleSelectRounded0">
                       <option>Value 1</option>
                       <option>Value 2</option>
                       <option>Value 3</option>
                    </select>
                 </div>
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
           <!-- Input addon -->
           <div class="card card-info">
              <div class="card-header">
                 <h3 class="card-title">Input Addon</h3>
              </div>
              <div class="card-body">
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                       <span class="input-group-text">@</span>
                    </div>
                    <input type="text" class="form-control" placeholder="Username">
                 </div>
                 <div class="input-group mb-3">
                    <input type="text" class="form-control">
                    <div class="input-group-append">
                       <span class="input-group-text">.00</span>
                    </div>
                 </div>
                 <div class="input-group">
                    <div class="input-group-prepend">
                       <span class="input-group-text">$</span>
                    </div>
                    <input type="text" class="form-control">
                    <div class="input-group-append">
                       <span class="input-group-text">.00</span>
                    </div>
                 </div>
                 <h4>With icons</h4>
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                       <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                    </div>
                    <input type="email" class="form-control" placeholder="Email">
                 </div>
                 <div class="input-group mb-3">
                    <input type="text" class="form-control">
                    <div class="input-group-append">
                       <span class="input-group-text"><i class="fas fa-check"></i></span>
                    </div>
                 </div>
                 <div class="input-group">
                    <div class="input-group-prepend">
                       <span class="input-group-text">
                       <i class="fas fa-dollar-sign"></i>
                       </span>
                    </div>
                    <input type="text" class="form-control">
                    <div class="input-group-append">
                       <div class="input-group-text"><i class="fas fa-ambulance"></i></div>
                    </div>
                 </div>
                 <h5 class="mt-4 mb-2">With checkbox and radio inputs</h5>
                 <div class="row">
                    <div class="col-lg-6">
                       <div class="input-group">
                          <div class="input-group-prepend">
                             <span class="input-group-text">
                             <input type="checkbox">
                             </span>
                          </div>
                          <input type="text" class="form-control">
                       </div>
                       <!-- /input-group -->
                    </div>
                    <!-- /.col-lg-6 -->
                    <div class="col-lg-6">
                       <div class="input-group">
                          <div class="input-group-prepend">
                             <span class="input-group-text"><input type="radio"></span>
                          </div>
                          <input type="text" class="form-control">
                       </div>
                       <!-- /input-group -->
                    </div>
                    <!-- /.col-lg-6 -->
                 </div>
                 <!-- /.row -->
                 <h5 class="mt-4 mb-2">With buttons</h5>
                 <p>Large: <code>.input-group.input-group-lg</code></p>
                 <div class="input-group input-group-lg mb-3">
                    <div class="input-group-prepend">
                       <button type="button" class="btn btn-warning dropdown-toggle" data-toggle="dropdown">
                       Action
                       </button>
                       <ul class="dropdown-menu">
                          <li class="dropdown-item"><a href="#">Action</a></li>
                          <li class="dropdown-item"><a href="#">Another action</a></li>
                          <li class="dropdown-item"><a href="#">Something else here</a></li>
                          <li class="dropdown-divider"></li>
                          <li class="dropdown-item"><a href="#">Separated link</a></li>
                       </ul>
                    </div>
                    <!-- /btn-group -->
                    <input type="text" class="form-control">
                 </div>
                 <!-- /input-group -->
                 <p>Normal</p>
                 <div class="input-group mb-3">
                    <div class="input-group-prepend">
                       <button type="button" class="btn btn-danger">Action</button>
                    </div>
                    <!-- /btn-group -->
                    <input type="text" class="form-control">
                 </div>
                 <!-- /input-group -->
                 <p>Small <code>.input-group.input-group-sm</code></p>
                 <div class="input-group input-group-sm">
                    <input type="text" class="form-control">
                    <span class="input-group-append">
                    <button type="button" class="btn btn-info btn-flat">Go!</button>
                    </span>
                 </div>
                 <!-- /input-group -->
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
           <!-- Horizontal Form -->
           <div class="card card-info">
              <div class="card-header">
                 <h3 class="card-title">Horizontal Form</h3>
              </div>
              <!-- /.card-header -->
              <!-- form start -->
              <form class="form-horizontal">
                 <div class="card-body">
                    <div class="form-group row">
                       <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                       <div class="col-sm-10">
                          <input type="email" class="form-control" id="inputEmail3" placeholder="Email">
                       </div>
                    </div>
                    <div class="form-group row">
                       <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label>
                       <div class="col-sm-10">
                          <input type="password" class="form-control" id="inputPassword3" placeholder="Password">
                       </div>
                    </div>
                    <div class="form-group row">
                       <div class="offset-sm-2 col-sm-10">
                          <div class="form-check">
                             <input type="checkbox" class="form-check-input" id="exampleCheck2">
                             <label class="form-check-label" for="exampleCheck2">Remember me</label>
                          </div>
                       </div>
                    </div>
                 </div>
                 <!-- /.card-body -->
                 <div class="card-footer">
                    <button type="submit" class="btn btn-info">Sign in</button>
                    <button type="submit" class="btn btn-default float-right">Cancel</button>
                 </div>
                 <!-- /.card-footer -->
              </form>
           </div>
           <!-- /.card -->
        </div>
        <!--/.col (left) -->
        <!-- right column -->
        <div class="col-md-6">
           <!-- Form Element sizes -->
           <div class="card card-success">
              <div class="card-header">
                 <h3 class="card-title">Different Height</h3>
              </div>
              <div class="card-body">
                 <input class="form-control form-control-lg" type="text" placeholder=".form-control-lg">
                 <br>
                 <input class="form-control" type="text" placeholder="Default input">
                 <br>
                 <input class="form-control form-control-sm" type="text" placeholder=".form-control-sm">
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
           <div class="card card-danger">
              <div class="card-header">
                 <h3 class="card-title">Different Width</h3>
              </div>
              <div class="card-body">
                 <div class="row">
                    <div class="col-3">
                       <input type="text" class="form-control" placeholder=".col-3">
                    </div>
                    <div class="col-4">
                       <input type="text" class="form-control" placeholder=".col-4">
                    </div>
                    <div class="col-5">
                       <input type="text" class="form-control" placeholder=".col-5">
                    </div>
                 </div>
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
           <!-- general form elements disabled -->
           <div class="card card-warning">
              <div class="card-header">
                 <h3 class="card-title">General Elements</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                 <form>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- text input -->
                          <div class="form-group">
                             <label>Text</label>
                             <input type="text" class="form-control" placeholder="Enter ...">
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <div class="form-group">
                             <label>Text Disabled</label>
                             <input type="text" class="form-control" placeholder="Enter ..." disabled>
                          </div>
                       </div>
                    </div>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- textarea -->
                          <div class="form-group">
                             <label>Textarea</label>
                             <textarea class="form-control" rows="3" placeholder="Enter ..."></textarea>
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <div class="form-group">
                             <label>Textarea Disabled</label>
                             <textarea class="form-control" rows="3" placeholder="Enter ..." disabled></textarea>
                          </div>
                       </div>
                    </div>
                    <!-- input states -->
                    <div class="form-group">
                       <label class="col-form-label" for="inputSuccess"><i class="fas fa-check"></i> Input with
                       success</label>
                       <input type="text" class="form-control is-valid" id="inputSuccess" placeholder="Enter ...">
                    </div>
                    <div class="form-group">
                       <label class="col-form-label" for="inputWarning"><i class="far fa-bell"></i> Input with
                       warning</label>
                       <input type="text" class="form-control is-warning" id="inputWarning" placeholder="Enter ...">
                    </div>
                    <div class="form-group">
                       <label class="col-form-label" for="inputError"><i class="far fa-times-circle"></i> Input with
                       error</label>
                       <input type="text" class="form-control is-invalid" id="inputError" placeholder="Enter ...">
                    </div>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- checkbox -->
                          <div class="form-group">
                             <div class="form-check">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label">Checkbox</label>
                             </div>
                             <div class="form-check">
                                <input class="form-check-input" type="checkbox" checked>
                                <label class="form-check-label">Checkbox checked</label>
                             </div>
                             <div class="form-check">
                                <input class="form-check-input" type="checkbox" disabled>
                                <label class="form-check-label">Checkbox disabled</label>
                             </div>
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <!-- radio -->
                          <div class="form-group">
                             <div class="form-check">
                                <input class="form-check-input" type="radio" name="radio1">
                                <label class="form-check-label">Radio</label>
                             </div>
                             <div class="form-check">
                                <input class="form-check-input" type="radio" name="radio1" checked>
                                <label class="form-check-label">Radio checked</label>
                             </div>
                             <div class="form-check">
                                <input class="form-check-input" type="radio" disabled>
                                <label class="form-check-label">Radio disabled</label>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- select -->
                          <div class="form-group">
                             <label>Select</label>
                             <select class="form-control">
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <div class="form-group">
                             <label>Select Disabled</label>
                             <select class="form-control" disabled>
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                    </div>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- Select multiple-->
                          <div class="form-group">
                             <label>Select Multiple</label>
                             <select multiple class="form-control">
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <div class="form-group">
                             <label>Select Multiple Disabled</label>
                             <select multiple class="form-control" disabled>
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                    </div>
                 </form>
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
           <!-- general form elements disabled -->
           <div class="card card-secondary">
              <div class="card-header">
                 <h3 class="card-title">Custom Elements</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body">
                 <form>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- checkbox -->
                          <div class="form-group">
                             <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="customCheckbox1" value="option1">
                                <label for="customCheckbox1" class="custom-control-label">Custom Checkbox</label>
                             </div>
                             <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="customCheckbox2" checked>
                                <label for="customCheckbox2" class="custom-control-label">Custom Checkbox checked</label>
                             </div>
                             <div class="custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="customCheckbox3" disabled>
                                <label for="customCheckbox3" class="custom-control-label">Custom Checkbox disabled</label>
                             </div>
                             <div class="custom-control custom-checkbox">
                                <input class="custom-control-input custom-control-input-danger" type="checkbox" id="customCheckbox4" checked>
                                <label for="customCheckbox4" class="custom-control-label">Custom Checkbox with custom color</label>
                             </div>
                             <div class="custom-control custom-checkbox">
                                <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="checkbox" id="customCheckbox5" checked>
                                <label for="customCheckbox5" class="custom-control-label">Custom Checkbox with custom color outline</label>
                             </div>
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <!-- radio -->
                          <div class="form-group">
                             <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="customRadio1" name="customRadio">
                                <label for="customRadio1" class="custom-control-label">Custom Radio</label>
                             </div>
                             <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="customRadio2" name="customRadio" checked>
                                <label for="customRadio2" class="custom-control-label">Custom Radio checked</label>
                             </div>
                             <div class="custom-control custom-radio">
                                <input class="custom-control-input" type="radio" id="customRadio3" disabled>
                                <label for="customRadio3" class="custom-control-label">Custom Radio disabled</label>
                             </div>
                             <div class="custom-control custom-radio">
                                <input class="custom-control-input custom-control-input-danger" type="radio" id="customRadio4" name="customRadio2" checked>
                                <label for="customRadio4" class="custom-control-label">Custom Radio with custom color</label>
                             </div>
                             <div class="custom-control custom-radio">
                                <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="radio" id="customRadio5" name="customRadio2">
                                <label for="customRadio5" class="custom-control-label">Custom Radio with custom color outline</label>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- select -->
                          <div class="form-group">
                             <label>Custom Select</label>
                             <select class="custom-select">
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <div class="form-group">
                             <label>Custom Select Disabled</label>
                             <select class="custom-select" disabled>
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                    </div>
                    <div class="row">
                       <div class="col-sm-6">
                          <!-- Select multiple-->
                          <div class="form-group">
                             <label>Custom Select Multiple</label>
                             <select multiple class="custom-select">
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                       <div class="col-sm-6">
                          <div class="form-group">
                             <label>Custom Select Multiple Disabled</label>
                             <select multiple class="custom-select" disabled>
                                <option>option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                                <option>option 4</option>
                                <option>option 5</option>
                             </select>
                          </div>
                       </div>
                    </div>
                    <div class="form-group">
                       <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id="customSwitch1">
                          <label class="custom-control-label" for="customSwitch1">Toggle this custom switch element</label>
                       </div>
                    </div>
                    <div class="form-group">
                       <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                          <input type="checkbox" class="custom-control-input" id="customSwitch3">
                          <label class="custom-control-label" for="customSwitch3">Toggle this custom switch element with custom colors danger/success</label>
                       </div>
                    </div>
                    <div class="form-group">
                       <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" disabled id="customSwitch2">
                          <label class="custom-control-label" for="customSwitch2">Disabled custom switch element</label>
                       </div>
                    </div>
                    <div class="form-group">
                       <label for="customRange1">Custom range</label>
                       <input type="range" class="custom-range" id="customRange1">
                    </div>
                    <div class="form-group">
                       <label for="customRange2">Custom range (custom-range-danger)</label>
                       <input type="range" class="custom-range custom-range-danger" id="customRange2">
                    </div>
                    <div class="form-group">
                       <label for="customRange3">Custom range (custom-range-teal)</label>
                       <input type="range" class="custom-range custom-range-teal" id="customRange3">
                    </div>
                    <div class="form-group">
                       <!-- <label for="customFile">Custom File</label> -->
                       <div class="custom-file">
                          <input type="file" class="custom-file-input" id="customFile">
                          <label class="custom-file-label" for="customFile">Choose file</label>
                       </div>
                    </div>
                    <div class="form-group">
                    </div>
                 </form>
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
        </div>
        <!--/.col (right) -->
     </div>
     <div class="card card-default">
        <div class="card-header">
           <h3 class="card-title">Select2 (Default Theme)</h3>
           <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-tool" data-card-widget="remove">
              <i class="fas fa-times"></i>
              </button>
           </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
           <div class="row">
              <div class="col-md-6">
                 <div class="form-group">
                    <label>Minimal</label>
                    <select class="form-control select2" style="width: 100%;">
                       <option selected="selected">Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
                 <div class="form-group">
                    <label>Disabled</label>
                    <select class="form-control select2" disabled="disabled" style="width: 100%;">
                       <option selected="selected">Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
              </div>
              <!-- /.col -->
              <div class="col-md-6">
                 <div class="form-group">
                    <label>Multiple</label>
                    <select class="select2" multiple="multiple" data-placeholder="Select a State" style="width: 100%;">
                       <option>Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
                 <div class="form-group">
                    <label>Disabled Result</label>
                    <select class="form-control select2" style="width: 100%;">
                       <option selected="selected">Alabama</option>
                       <option>Alaska</option>
                       <option disabled="disabled">California (disabled)</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
              </div>
              <!-- /.col -->
           </div>
           <!-- /.row -->
           <h5>Custom Color Variants</h5>
           <div class="row">
              <div class="col-12 col-sm-6">
                 <div class="form-group">
                    <label>Minimal (.select2-danger)</label>
                    <select class="form-control select2 select2-danger" data-dropdown-css-class="select2-danger" style="width: 100%;">
                       <option selected="selected">Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
              </div>
              <!-- /.col -->
              <div class="col-12 col-sm-6">
                 <div class="form-group">
                    <label>Multiple (.select2-purple)</label>
                    <div class="select2-purple">
                       <select class="select2" multiple="multiple" data-placeholder="Select a State" data-dropdown-css-class="select2-purple" style="width: 100%;">
                          <option>Alabama</option>
                          <option>Alaska</option>
                          <option>California</option>
                          <option>Delaware</option>
                          <option>Tennessee</option>
                          <option>Texas</option>
                          <option>Washington</option>
                       </select>
                    </div>
                 </div>
                 <!-- /.form-group -->
              </div>
              <!-- /.col -->
           </div>
           <!-- /.row -->
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
           Visit <a href="https://select2.github.io/">Select2 documentation</a> for more examples and information about
           the plugin.
        </div>
     </div>
     <!-- SELECT2 EXAMPLE -->
     <div class="card card-default">
        <div class="card-header">
           <h3 class="card-title">Select2 (Bootstrap4 Theme)</h3>
           <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-tool" data-card-widget="remove">
              <i class="fas fa-times"></i>
              </button>
           </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
           <div class="row">
              <div class="col-md-6">
                 <div class="form-group">
                    <label>Minimal</label>
                    <select class="form-control select2bs4" style="width: 100%;">
                       <option selected="selected">Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
                 <div class="form-group">
                    <label>Disabled</label>
                    <select class="form-control select2bs4" disabled="disabled" style="width: 100%;">
                       <option selected="selected">Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
              </div>
              <!-- /.col -->
              <div class="col-md-6">
                 <div class="form-group">
                    <label>Multiple</label>
                    <select class="select2bs4" multiple="multiple" data-placeholder="Select a State"
                       style="width: 100%;">
                       <option>Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
                 <div class="form-group">
                    <label>Disabled Result</label>
                    <select class="form-control select2bs4" style="width: 100%;">
                       <option selected="selected">Alabama</option>
                       <option>Alaska</option>
                       <option disabled="disabled">California (disabled)</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
              </div>
              <!-- /.col -->
           </div>
           <!-- /.row -->
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
           Visit <a href="https://select2.github.io/">Select2 documentation</a> for more examples and information about
           the plugin.
        </div>
     </div>
     <!-- /.card -->
     <div class="card card-default">
        <div class="card-header">
           <h3 class="card-title">Bootstrap Duallistbox</h3>
           <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
              <i class="fas fa-minus"></i>
              </button>
              <button type="button" class="btn btn-tool" data-card-widget="remove">
              <i class="fas fa-times"></i>
              </button>
           </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body">
           <div class="row">
              <div class="col-12">
                 <div class="form-group">
                    <label>Multiple</label>
                    <select class="duallistbox" multiple="multiple">
                       <option selected>Alabama</option>
                       <option>Alaska</option>
                       <option>California</option>
                       <option>Delaware</option>
                       <option>Tennessee</option>
                       <option>Texas</option>
                       <option>Washington</option>
                    </select>
                 </div>
                 <!-- /.form-group -->
              </div>
              <!-- /.col -->
           </div>
           <!-- /.row -->
        </div>
        <!-- /.card-body -->
        <div class="card-footer">
           Visit <a href="https://github.com/istvan-ujjmeszaros/bootstrap-duallistbox#readme">Bootstrap Duallistbox</a> for more examples and information about
           the plugin.
        </div>
     </div>
     <!-- /.card -->
     <div class="row">
        <div class="col-md-6">
           <div class="card card-danger">
              <div class="card-header">
                 <h3 class="card-title">Input masks</h3>
              </div>
              <div class="card-body">
                 <!-- Date dd/mm/yyyy -->
                 <div class="form-group">
                    <label>Date masks:</label>
                    <div class="input-group">
                       <div class="input-group-prepend">
                          <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                       </div>
                       <input type="text" class="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask>
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
                 <!-- Date mm/dd/yyyy -->
                 <div class="form-group">
                    <div class="input-group">
                       <div class="input-group-prepend">
                          <span class="input-group-text"><i class="far fa-calendar-alt"></i></span>
                       </div>
                       <input type="text" class="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="mm/dd/yyyy" data-mask>
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
                 <!-- phone mask -->
                 <div class="form-group">
                    <label>US phone mask:</label>
                    <div class="input-group">
                       <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-phone"></i></span>
                       </div>
                       <input type="text" class="form-control" data-inputmask='"mask": "(999) 999-9999"' data-mask>
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
                 <!-- phone mask -->
                 <div class="form-group">
                    <label>Intl US phone mask:</label>
                    <div class="input-group">
                       <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-phone"></i></span>
                       </div>
                       <input type="text" class="form-control"
                          data-inputmask="'mask': ['999-999-9999 [x99999]', '+099 99 99 9999[9]-9999']" data-mask>
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
                 <!-- IP mask -->
                 <div class="form-group">
                    <label>IP mask:</label>
                    <div class="input-group">
                       <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-laptop"></i></span>
                       </div>
                       <input type="text" class="form-control" data-inputmask="'alias': 'ip'" data-mask>
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
           <div class="card card-info">
              <div class="card-header">
                 <h3 class="card-title">Color & Time Picker</h3>
              </div>
              <div class="card-body">
                 <!-- Color Picker -->
                 <div class="form-group">
                    <label>Color picker:</label>
                    <input type="text" class="form-control my-colorpicker1">
                 </div>
                 <!-- /.form group -->
                 <!-- Color Picker -->
                 <div class="form-group">
                    <label>Color picker with addon:</label>
                    <div class="input-group my-colorpicker2">
                       <input type="text" class="form-control">
                       <div class="input-group-append">
                          <span class="input-group-text"><i class="fas fa-square"></i></span>
                       </div>
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
                 <!-- time Picker -->
                 <div class="bootstrap-timepicker">
                    <div class="form-group">
                       <label>Time picker:</label>
                       <div class="input-group date" id="timepicker" data-target-input="nearest">
                          <input type="text" class="form-control datetimepicker-input" data-target="#timepicker"/>
                          <div class="input-group-append" data-target="#timepicker" data-toggle="datetimepicker">
                             <div class="input-group-text"><i class="far fa-clock"></i></div>
                          </div>
                       </div>
                       <!-- /.input group -->
                    </div>
                    <!-- /.form group -->
                 </div>
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
        </div>
        <!-- /.col (left) -->
        <div class="col-md-6">
           <div class="card card-primary">
              <div class="card-header">
                 <h3 class="card-title">Date picker</h3>
              </div>
              <div class="card-body">
                 <!-- Date -->
                 <div class="form-group">
                    <label>Date:</label>
                    <div class="input-group date" id="reservationdate" data-target-input="nearest">
                       <input type="text" class="form-control datetimepicker-input" data-target="#reservationdate"/>
                       <div class="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
                          <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                       </div>
                    </div>
                 </div>
                 <!-- Date and time -->
                 <div class="form-group">
                    <label>Date and time:</label>
                    <div class="input-group date" id="reservationdatetime" data-target-input="nearest">
                       <input type="text" class="form-control datetimepicker-input" data-target="#reservationdatetime"/>
                       <div class="input-group-append" data-target="#reservationdatetime" data-toggle="datetimepicker">
                          <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                       </div>
                    </div>
                 </div>
                 <!-- /.form group -->
                 <!-- Date range -->
                 <div class="form-group">
                    <label>Date range:</label>
                    <div class="input-group">
                       <div class="input-group-prepend">
                          <span class="input-group-text">
                          <i class="far fa-calendar-alt"></i>
                          </span>
                       </div>
                       <input type="text" class="form-control float-right" id="reservation">
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
                 <!-- Date and time range -->
                 <div class="form-group">
                    <label>Date and time range:</label>
                    <div class="input-group">
                       <div class="input-group-prepend">
                          <span class="input-group-text"><i class="far fa-clock"></i></span>
                       </div>
                       <input type="text" class="form-control float-right" id="reservationtime">
                    </div>
                    <!-- /.input group -->
                 </div>
                 <!-- /.form group -->
                 <!-- Date and time range -->
                 <div class="form-group">
                    <label>Date range button:</label>
                    <div class="input-group">
                       <button type="button" class="btn btn-default float-right" id="daterange-btn">
                       <i class="far fa-calendar-alt"></i> Date range picker
                       <i class="fas fa-caret-down"></i>
                       </button>
                    </div>
                 </div>
                 <!-- /.form group -->
              </div>
              <div class="card-footer">
                 Visit <a href="https://getdatepicker.com/5-4/">tempusdominus </a> for more examples and information about
                 the plugin.
              </div>
              <!-- /.card-body -->
           </div>
           <!-- /.card -->
           <!-- iCheck -->
           <div class="card card-success">
              <div class="card-header">
                 <h3 class="card-title">iCheck Bootstrap - Checkbox &amp; Radio Inputs</h3>
              </div>
              <div class="card-body">
                 <!-- Minimal style -->
                 <div class="row">
                    <div class="col-sm-6">
                       <!-- checkbox -->
                       <div class="form-group clearfix">
                          <div class="icheck-primary d-inline">
                             <input type="checkbox" id="checkboxPrimary1" checked>
                             <label for="checkboxPrimary1">
                             </label>
                          </div>
                          <div class="icheck-primary d-inline">
                             <input type="checkbox" id="checkboxPrimary2">
                             <label for="checkboxPrimary2">
                             </label>
                          </div>
                          <div class="icheck-primary d-inline">
                             <input type="checkbox" id="checkboxPrimary3" disabled>
                             <label for="checkboxPrimary3">
                             Primary checkbox
                             </label>
                          </div>
                       </div>
                    </div>
                    <div class="col-sm-6">
                       <!-- radio -->
                       <div class="form-group clearfix">
                          <div class="icheck-primary d-inline">
                             <input type="radio" id="radioPrimary1" name="r1" checked>
                             <label for="radioPrimary1">
                             </label>
                          </div>
                          <div class="icheck-primary d-inline">
                             <input type="radio" id="radioPrimary2" name="r1">
                             <label for="radioPrimary2">
                             </label>
                          </div>
                          <div class="icheck-primary d-inline">
                             <input type="radio" id="radioPrimary3" name="r1" disabled>
                             <label for="radioPrimary3">
                             Primary radio
                             </label>
                          </div>
                       </div>
                    </div>
                 </div>
                 <!-- Minimal red style -->
                 <div class="row">
                    <div class="col-sm-6">
                       <!-- checkbox -->
                       <div class="form-group clearfix">
                          <div class="icheck-danger d-inline">
                             <input type="checkbox" checked id="checkboxDanger1">
                             <label for="checkboxDanger1">
                             </label>
                          </div>
                          <div class="icheck-danger d-inline">
                             <input type="checkbox" id="checkboxDanger2">
                             <label for="checkboxDanger2">
                             </label>
                          </div>
                          <div class="icheck-danger d-inline">
                             <input type="checkbox" disabled id="checkboxDanger3">
                             <label for="checkboxDanger3">
                             Danger checkbox
                             </label>
                          </div>
                       </div>
                    </div>
                    <div class="col-sm-6">
                       <!-- radio -->
                       <div class="form-group clearfix">
                          <div class="icheck-danger d-inline">
                             <input type="radio" name="r2" checked id="radioDanger1">
                             <label for="radioDanger1">
                             </label>
                          </div>
                          <div class="icheck-danger d-inline">
                             <input type="radio" name="r2" id="radioDanger2">
                             <label for="radioDanger2">
                             </label>
                          </div>
                          <div class="icheck-danger d-inline">
                             <input type="radio" name="r2" disabled id="radioDanger3">
                             <label for="radioDanger3">
                             Danger radio
                             </label>
                          </div>
                       </div>
                    </div>
                 </div>
                 <!-- Minimal red style -->
                 <div class="row">
                    <div class="col-sm-6">
                       <!-- checkbox -->
                       <div class="form-group clearfix">
                          <div class="icheck-success d-inline">
                             <input type="checkbox" checked id="checkboxSuccess1">
                             <label for="checkboxSuccess1">
                             </label>
                          </div>
                          <div class="icheck-success d-inline">
                             <input type="checkbox" id="checkboxSuccess2">
                             <label for="checkboxSuccess2">
                             </label>
                          </div>
                          <div class="icheck-success d-inline">
                             <input type="checkbox" disabled id="checkboxSuccess3">
                             <label for="checkboxSuccess3">
                             Success checkbox
                             </label>
                          </div>
                       </div>
                    </div>
                    <div class="col-sm-6">
                       <!-- radio -->
                       <div class="form-group clearfix">
                          <div class="icheck-success d-inline">
                             <input type="radio" name="r3" checked id="radioSuccess1">
                             <label for="radioSuccess1">
                             </label>
                          </div>
                          <div class="icheck-success d-inline">
                             <input type="radio" name="r3" id="radioSuccess2">
                             <label for="radioSuccess2">
                             </label>
                          </div>
                          <div class="icheck-success d-inline">
                             <input type="radio" name="r3" disabled id="radioSuccess3">
                             <label for="radioSuccess3">
                             Success radio
                             </label>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                 Many more skins available. <a href="https://bantikyan.github.io/icheck-bootstrap/">Documentation</a>
              </div>
           </div>
           <!-- /.card -->
        </div>
        <!-- /.col (right) -->
     </div>
     <!-- /.row -->
     <div class="row">
        <div class="col-md-12">
           <div class="card card-default">
              <div class="card-header">
                 <h3 class="card-title">bs-stepper</h3>
              </div>
              <div class="card-body p-0">
                 <div class="bs-stepper">
                    <div class="bs-stepper-header" role="tablist">
                       <!-- your steps here -->
                       <div class="step" data-target="#logins-part">
                          <button type="button" class="step-trigger" role="tab" aria-controls="logins-part" id="logins-part-trigger">
                          <span class="bs-stepper-circle">1</span>
                          <span class="bs-stepper-label">Logins</span>
                          </button>
                       </div>
                       <div class="line"></div>
                       <div class="step" data-target="#information-part">
                          <button type="button" class="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                          <span class="bs-stepper-circle">2</span>
                          <span class="bs-stepper-label">Various information</span>
                          </button>
                       </div>
                    </div>
                    <div class="bs-stepper-content">
                       <!-- your steps content here -->
                       <div id="logins-part" class="content" role="tabpanel" aria-labelledby="logins-part-trigger">
                          <div class="form-group">
                             <label for="exampleInputEmail1">Email address</label>
                             <input type="email" class="form-control" id="exampleInputEmail3" placeholder="Enter email">
                          </div>
                          <div class="form-group">
                             <label for="exampleInputPassword1">Password</label>
                             <input type="password" class="form-control" id="exampleInputPassword3" placeholder="Password">
                          </div>
                          <button class="btn btn-primary" onclick="stepper.next()">Next</button>
                       </div>
                       <div id="information-part" class="content" role="tabpanel" aria-labelledby="information-part-trigger">
                          <div class="form-group">
                             <label for="exampleInputFile">File input</label>
                             <div class="input-group">
                                <div class="custom-file">
                                   <input type="file" class="custom-file-input" id="exampleInputFile4">
                                   <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                                </div>
                                <div class="input-group-append">
                                   <span class="input-group-text">Upload</span>
                                </div>
                             </div>
                          </div>
                          <button class="btn btn-primary" onclick="stepper.previous()">Previous</button>
                          <button type="submit" class="btn btn-primary">Submit</button>
                       </div>
                    </div>
                 </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                 Visit <a href="https://github.com/Johann-S/bs-stepper/#how-to-use-it">bs-stepper documentation</a> for more examples and information about the plugin.
              </div>
           </div>
           <!-- /.card -->
        </div>
     </div>
     <!-- /.row -->
     <div class="row">
        <div class="col-md-12">
           <div class="card card-default">
              <div class="card-header">
                 <h3 class="card-title">Dropzone.js <small><em>jQuery File Upload</em> like look</small></h3>
              </div>
              <div class="card-body">
                 <div id="actions" class="row">
                    <div class="col-lg-6">
                       <div class="btn-group w-100">
                          <span class="btn btn-success col fileinput-button">
                          <i class="fas fa-plus"></i>
                          <span>Add files</span>
                          </span>
                          <button type="submit" class="btn btn-primary col start">
                          <i class="fas fa-upload"></i>
                          <span>Start upload</span>
                          </button>
                          <button type="reset" class="btn btn-warning col cancel">
                          <i class="fas fa-times-circle"></i>
                          <span>Cancel upload</span>
                          </button>
                       </div>
                    </div>
                    <div class="col-lg-6 d-flex align-items-center">
                       <div class="fileupload-process w-100">
                          <div id="total-progress" class="progress progress-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                             <div class="progress-bar progress-bar-success" style="width:0%;" data-dz-uploadprogress></div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="table table-striped files" id="previews">
                    <div id="template" class="row mt-2">
                       <div class="col-auto">
                          <span class="preview"><img src="data:," alt="" data-dz-thumbnail /></span>
                       </div>
                       <div class="col d-flex align-items-center">
                          <p class="mb-0">
                             <span class="lead" data-dz-name></span>
                             (<span data-dz-size></span>)
                          </p>
                          <strong class="error text-danger" data-dz-errormessage></strong>
                       </div>
                       <div class="col-4 d-flex align-items-center">
                          <div class="progress progress-striped active w-100" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                             <div class="progress-bar progress-bar-success" style="width:0%;" data-dz-uploadprogress></div>
                          </div>
                       </div>
                       <div class="col-auto d-flex align-items-center">
                          <div class="btn-group">
                             <button class="btn btn-primary start">
                             <i class="fas fa-upload"></i>
                             <span>Start</span>
                             </button>
                             <button data-dz-remove class="btn btn-warning cancel">
                             <i class="fas fa-times-circle"></i>
                             <span>Cancel</span>
                             </button>
                             <button data-dz-remove class="btn btn-danger delete">
                             <i class="fas fa-trash"></i>
                             <span>Delete</span>
                             </button>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                 Visit <a href="https://www.dropzonejs.com">dropzone.js documentation</a> for more examples and information about the plugin.
              </div>
           </div>
           <!-- /.card -->
        </div>
     </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->