<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <table class="table table-borderless">
            <tr>
               <td style="width:15%;"><img src="../assets/img/myKadaiLogo.png" style="height:60px;width:60px;" ></td>
               <td style=" vertical-align: middle">
                  <span>
                     <h3 class="mb-0" style="text-align: center;font-weight: bold;">MYKADAI.COM</h3>
                  </span>
               </td>
            </tr>
         </table>
    </div>
    <div class="card-body">
        <p class="login-box-msg">
            You forgot your password? Here you can easily retrieve a new
            password.
        </p>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
            <div class="input-group mb-3">
                <input
                    formControlName="email"
                    type="email"
                    class="form-control"
                    placeholder="Email"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <pf-button [type]="'submit'" [block]="true">
                        Request new password
                    </pf-button>
                </div>
            </div>
        </form>
        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Login</a>
        </p>
    </div>
</div>
