<div [style.display]="'block'" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="card-header text-center">
                <div class="row">
                    <div class="col-md-12">
                        <img src="../assets/img/myKadaiLogo.png" style="height:80px;width:80px;">
                    </div>
                </div>
            </div>
            <h5 class="text-center mt-3 font-weight-bold">REGISTER</h5>
            <div class="modal-body">
                <div style="border-color:#e9ecef #e9ecef #dee2e6 !important;padding: 10px;">
                    <form (ngSubmit)="onSubmit()" [formGroup]="shopOwnerDetails" id="shopOwnerDetails">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control text-capitalize" placeholder="First Name"
                                           id="firstName" formControlName="firstName">
                                    <span *ngIf="shopOwnerRegisterErrors.firstName"
                                          class="error-text">{{shopOwnerRegisterErrors.firstName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control text-capitalize" placeholder="Last Name"
                                           id="lastName"
                                           formControlName="lastName">
                                    <span *ngIf="shopOwnerRegisterErrors.lastName"
                                          class="error-text">{{shopOwnerRegisterErrors.lastName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Email Address" id="emailId"
                                           formControlName="emailId">
                                    <span *ngIf="shopOwnerRegisterErrors.emailId"
                                          class="error-text">{{shopOwnerRegisterErrors.emailId}}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control"
                                           placeholder="Phone Number" id="phoneNumber" formControlName="phoneNumber"
                                           (keypress)="OnlyNumbers($event)" maxlength="10">
                                    <span *ngIf="shopOwnerRegisterErrors.phoneNumber"
                                          class="error-text">{{shopOwnerRegisterErrors.phoneNumber}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="User Name" id="userName"
                                           formControlName="userName" maxlength="20" autocomplete="new-userName">
                                    <span *ngIf="shopOwnerRegisterErrors.userName"
                                          class="error-text">{{shopOwnerRegisterErrors.userName}}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="password" class="form-control" [(ngModel)]="password"
                                           autocomplete="new-password"
                                           placeholder="Password" id="password" formControlName="password" minlength="8"
                                           maxlength="10">
                                    <i class="toggle-password fa fa-eye" data-toggle="tooltip" data-placement="top"
                                       title="Click to Show Password" (click)="togglePasswordVisibility()"
                                       [ngClass]="{'fa-eye-slash': isPasswordVisible, 'fa-eye': !isPasswordVisible}"
                                       aria-hidden="true"></i>
                                    <span *ngIf="shopOwnerRegisterErrors.password"
                                          class="error-text">{{shopOwnerRegisterErrors.password}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 form-group">
                            <input type="checkbox" id="enabled" formControlName="enabled">
                            <label class="ml-2"> I agree to the <a href="" data-toggle="modal" data-target="#myModal">Terms
                                &
                                Condition</a></label><br>
                            <span *ngIf="shopOwnerRegisterErrors.enabled"
                                  class="error-text">{{shopOwnerRegisterErrors.enabled}}</span>
                        </div>
                        <div class="text-center mt-1">
                            <button type="submit" class="btn btn-success font-weight-bolder btn-block mb-3">REGISTER
                            </button>
                        </div>
                    </form>
                    <!-- <div class="row">
                       <label class="text-align col-md-12 mt-3" type="button" (click)="onNavigation('login')">login</label>
                       </div> -->
                    <p class="text-center text-muted mt-3 mb-0">Have already an account?
                        <a class="text-md-center font-weight-bold text-success" type="button" [routerLink]="['/']">Login
                            here</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>