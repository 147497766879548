export class MultiSelectData {
    id: number;
    name: string;
    shopProductMRP: number;
    shopProductSRP: number;
    shopProductTax: number = 0;
    shopProductDiscountType: number = 0;
    shopMinOrderQty: number = 0;
    shopMaxOrderQty: number = 0;
    mainProductCategory: number = 0;
    subProductCategory: number = 0;
    shopId: number = 0;
    status: string = "";

    constructor(products: number, productName: string, /*shopProductMRP: number,
                shopProductSRP: number*//*, shopProductTax: number, discountType: number,
                maxOrderQty: number, minOrderQty: number, mainProductCategory: number,
                subProductCategory: number, shopId: number, statuses: string*/) {
        // constructor(products: number, productName: string) {
        this.id = products;
        this.name = productName;
        /*this.shopProductMRP = shopProductMRP;
        this.shopProductSRP = shopProductSRP;*/
        /*this.shopProductTax = shopProductTax;
        this.shopProductDiscountType = discountType;
        this.shopMinOrderQty = minOrderQty;
        this.shopMaxOrderQty = maxOrderQty;
        this.mainProductCategory = mainProductCategory;
        this.subProductCategory = subProductCategory;
        this.shopId = shopId;
        this.status = statuses;*/
    }
}