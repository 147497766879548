export interface productDetails {
    /*shop: string;
    shopType: string;
    subProductCategory: number;
    mainProductCategory: number;
    productQuantity: number;
    productUOM: number;
    fromDate: string;
    toDate: string;
    manufactureCountry: string;
    productId: number;
    productName : string;
    productImage: string;
    shopId: number;
    productMRP: number;
    productSRP: number;
    productTax: number;
    productDiscountType: number;
    minOrderQty: number;
    maxOrderQty: number;
    addedBy: string;*/
    productId: number;
    productName: string;
    productImage: string;
    productDesc: string;
    minOrderQty: number;
    maxOrderQty: number;
    addedBy: string;
    mainProductCategory: number;
    subProductCategory: number;
    modifiedAt:number;
    enabled: boolean;
    isRemoved: boolean;
}


export class productDetailsData {
    productId: number;
    productName: string;
    productImage: string;
    productDesc: string;
    minOrderQty: number;
    maxOrderQty: number;
    addedBy: string;
    mainProductCategory: number;
    subProductCategory: number;
    modifiedAt:number;
    enabled: boolean;
}
