<div class="container-fluid overflow-hide">
    <div class="row">
        <div class="col-md-12 five">
            <h1>My orders</h1>
        </div>
    </div>
    <form [formGroup]="orderProcessing" id="orderProcessing">
        <!--<div class="row mt-3">
            <div class="col-md-4">
                <select class="form-control form-control-sm" id="shopName"
                        (change)="selectShopOrders($event)">
                    <option value="" disabled selected>select Shop Name</option>
                    <option *ngFor="let shop of shopList" value="{{shop.traderId}}">{{shop.traderName}}</option>
                </select>
            </div>
        </div>-->
    </form>
    <div class="card container-fluid p-2 mt-3">
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="btn-group btn-group-justified">
                    <a class="btn btn-white" (click)="orderPlaced()">ORDER PLACED <span class="badge">{{orderPlacedCount}}</span></a>
                    <a type="button" class="btn btn-white" (click)="orderAccepted()">ORDER ACCEPTED <span class="badge">{{orderAcceptedCount}}</span></a>
                    <!--<a type="button" class="btn btn-white" (click)="orderReady()">READY FOR PICKUP <span class="badge">{{orderPickupCount}}</span></a>-->
                    <a type="button" class="btn btn-white" (click)="orderClosed()">ORDER DELIVERED <span class="badge">{{orderClosedCount}}</span></a>
                </div>
            </div>
        </div>
        <div class="card container bg-light mt-3">
          <div class="row mt-2 mb-2">
              <div class="col-lg-8 col-12"></div>
              <div class="input-group col-md-9">
                  <input type="search" class="form-control rounded form-control-sm" [(ngModel)]="searchText" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" />
                  <span class="input-group-text bg-light border-0" id="search-addon"><i class="fas fa-search"></i></span>
              </div>
          </div>
        </div>
        <!--<div class="container-fluid" [style.display]="orderPlacedShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md tableFixHead">
                    <table class="table rounded  text-center">
                        <thead class="header rounded sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Shop Name</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Order Type</th>
                            <th>Payment Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let orderplace of this.orderPlacedList | filter: searchText; let i = index;" (click)="modalShow(orderplace.orderId);">
                            <td>{{i+1}}</td>
                            <td>{{orderplace.orderId}}</td>
                            <td></td>
                            <td>RS. {{orderplace.totalAmount}}</td>
                            <td><span class="badge blue-badge"><i class="bi-dot text-blue"></i>{{orderplace.orderStatus}}</span></td>
                            <td>{{orderplace.orderType}}</td>
                            <td>
                                <p *ngIf="orderplace.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">✔ Paid</span>
                                </p>
                                <p *ngIf="orderplace.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">✘Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>-->

        <div class="container-fluid" [style.display]="orderPlacedShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md tableFixHead">
                    <table class="table rounded  text-center">
                        <thead class="header rounded sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Shop Name</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Payment Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let ordered of this.retailerBasedOrder | filter: searchText; let i = index;" (click)="orderProductModalShow(ordered.orderId, 'Ordered');">
                            <td>{{i+1}}</td>
                            <td>{{ordered.orderUID}}</td>
                            <td class="text-capitalize">{{ordered.createdBy}}
                                <br>
                                {{ordered.deliveryAddress.area}}, {{ordered.deliveryAddress.city}}, {{ordered.deliveryAddress.state}}
                            </td>
                            <td>RS. {{getPayableAmount(ordered.listOrderDetails) | currency:'INR':''}}</td>
                            <td><span class="badge blue-badge"><i class="bi-dot text-blue"></i>{{ordered.orderStatus}}</span>
                                <br class="mt-2"> {{ordered.orderDate | date: 'dd/MM/yyyy'}}
                            </td>
                            <td>
                                <p *ngIf="ordered.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">Paid</span>
                                </p>
                                <p *ngIf="ordered.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="container-fluid" [style.display]="orderAcceptedShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md tableFixHead">
                    <table class="table text-center">
                        <thead class="header sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Shop Name</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Payment Status</th>
                            <th *ngIf="appCode != 'MYKADAI'">Print</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let orderAccepted of this.retailerAcceptedOrder | filter: searchText; let i = index;">
                            <td (click)="orderProductModalShow(orderAccepted.orderId, 'Accepted');">{{i+1}}</td>
                            <td (click)="orderProductModalShow(orderAccepted.orderId, 'Accepted');">{{orderAccepted.orderUID}}</td>
                            <td  class="text-capitalize" (click)="orderProductModalShow(orderAccepted.orderId, 'Accepted');">{{orderAccepted.createdBy}}
                                <br>
                                {{orderAccepted.deliveryAddress.area}}, {{orderAccepted.deliveryAddress.city}}, {{orderAccepted.deliveryAddress.state}}
                            </td>
                            <td (click)="orderProductModalShow(orderAccepted.orderId, 'Accepted');">RS. {{getPayableAmount(orderAccepted.listOrderDetails) | currency:'INR':''}}</td>
                            <td (click)="orderProductModalShow(orderAccepted.orderId, 'Accepted');"><span class="badge save-badge">{{orderAccepted.orderStatus}}</span>
                                <br class="mt-2">
                                {{orderAccepted.orderDate | date: 'dd/MM/yyyy'}}
                            </td>
                            <td (click)="orderProductModalShow(orderAccepted.orderId, 'Accepted');">
                                <p *ngIf="orderAccepted.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">Paid</span>
                                </p>
                                <p *ngIf="orderAccepted.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">Not Paid</span>
                                </p>
                            </td>
                            <td *ngIf="appCode != 'MYKADAI'"><button type="button" class="accept btn btn" (click)="invoicePrint(orderAccepted.orderId)">Print</button></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--<div class="container-fluid" [style.display]="orderReadyShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
                    <table class="table text-center">
                        <thead class="header sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Order Type</th>
                            <th>Payment Status</th>
&lt;!&ndash;                            <th>Resend SMS</th>&ndash;&gt;
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let orderplace of this.orderPickupList | filter: searchText;let i = index;" (click)="orderProductModalShow(orderplace.orderId);">
                            <td>{{i+1}}</td>
                            <td>{{orderplace.orderId}}</td>
                            <td>RS. {{orderplace.totalAmount}}</td>
                            <td><span class="badge yellow-badge">{{orderplace.orderStatus}}</span></td>
                            <td>{{orderplace.orderType}}</td>
                            <td>
                                <p *ngIf="orderplace.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">✔ Paid</span>
                                </p>
                                <p *ngIf="orderplace.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">✘Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
-->
        <div class="container-fluid" [style.display]="orderCloseShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md tableFixHead">
                    <table class="table  text-center">
                        <thead class="header sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Shop Name</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Payment Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let delivered of this.retailerDeliveredOrder | filter: searchText; let i = index;" (click)="orderProductModalShow(delivered.orderId, 'Delivered');">
                            <td>{{i+1}}</td>
                            <td>{{delivered.orderUID}}</td>
                            <td class="text-capitalize">{{delivered.createdBy}}
                                <br>
                                {{delivered.deliveryAddress.area}}, {{delivered.deliveryAddress.city}}, {{delivered.deliveryAddress.state}}
                            </td>
                            <td>RS. {{getPayableAmount(delivered.listOrderDetails) | currency:'INR':''}}</td>
                            <td><span class="badge danger-badge">{{delivered.orderStatus}}</span>
                                <br>
                                {{delivered.deliveredDate | date: 'dd/MM/yyyy'}}
                            </td>
                           <!-- <td>{{delivered.orderType}}</td>-->
                            <td>
                                <p *ngIf="delivered.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">✔Paid</span>
                                </p>
                                <p *ngIf="delivered.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">✘Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        <tr *ngIf="orderClosedCount == 0">
                            <td> No Order Found</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div [style.display]="isVisible ? 'block' : 'none'" style="background-color: rgba(229,229,229,0.51)" class="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="row modal-header">
                <div class="col-10">
                    <h3 class="text-center font-weight-bolder text-uppercase text-danger">Order Details</h3>
                    <h5 class="modal-title text-center text-gray-dark text-bold text-capitalize">{{shopName}}</h5>
                    <h6 class="modal-title text-center" id="exampleModalLabel"><b>Order ID - {{this.orderUID}}</b></h6>
                </div>
               <!-- <div class="col-md-1 sm-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                </div>-->
                <div class="col-2" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" (click)="closeAlert();">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mt-3 mb-3" *ngIf="deliveryDateShow">
                    <div class="col-md-12 flex-fill">
                        <div class="form-floating">
                            <input type="date" class="form-control" id="floatingPassword" placeholder="Password" [(ngModel)]="selectedDate" (change)="acceptShow($event)">
                            <label for="floatingPassword">Delivery Date</label>
                        </div>
                    </div>
                    <span *ngIf="!isDisabled" class="text-danger mt-3 col-12">Note: Please choose the delivery date before accept the order</span>
                </div>
                <div class="container-fluid bg-primary p-2 sticky-top justify-content-center align-items-center">
                    <div class="col-md-12 text-center font-weight-bolder">
                        <h4 class="font-weight-bolder">MY CART</h4>
                    </div>
                </div>
                <div class="container-fluid y-scrollable">
                    <div class="col-md-12 table-responsive-md">
                        <table class="table table-borderless">
                            <thead>
                            <tr>
                                <th class="col-md-9">Item</th>
                                <th class="col-md-1">Price</th>
                                <th class="col-md-1">Quantity</th>
                                <th class="col-md-1">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let order of productList">
                                <td>{{order.itemName}}</td>
                                <td class="text-center">{{order.itemSRP | currency:'INR':''}} </td>
                                <td class="text-center">{{order.itemCount}}</td>
                                <td class="text-center">{{order.itemSRP * order.itemCount | currency:'INR':''}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="container-fluid bg-light align-content-center">
                    <div class="col-md-12 text-center font-weight-bolder">
                        <h6 class="font-weight-bold">Payment Details</h6>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row mt-3">
                        <div class="col-lg-8 col-8">
                            <label class="font-weight-bold">Total</label>
                        </div>
                        <div class="col-lg-4 col-4">
                            <label class="font-weight-bold text-end">RS {{this.totalAmount | currency:'INR':''}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row mt-3">
                        <div class="col-lg-8 col-8">
                            <label class="font-weight-bold">Payable Amount</label>
                        </div>
                        <div class="col-lg-4 col-4">
                            <label class="font-weight-bold text-end">RS {{this.totalAmount | currency:'INR':''}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row mt-3">
                    <div class="col-md-12 text-center">
                        <button type="button" class="accept btn btn" [style.Display]="this.isAccepted ? 'block' : 'none'" *ngIf="!isDisabled" disabled (click)="orderStatus($event)">Accepted</button>
                        <button type="button" class="accept btn btn" [style.Display]="this.isAccepted ? 'block' : 'none'" *ngIf="isDisabled" (click)="orderStatus($event)">Accepted</button>
                        <!--  <button type="button" class="accept btn btn" [style.Display]="this.isReady ? 'block' : 'none'" (click)="orderStatus($event)">Ready For Pickup</button>-->
                        <button type="button" class="accept btn btn" [style.Display]="this.isClosed ? 'block' : 'none'" (click)="orderStatus($event)">Delivered</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" role="dialog" [style.display]="isVisibleCancel ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form [formGroup]="orderProcessing">
            <div class="modal-header">
                <h5 class="modal-title text-center">Order Cancel Remarks ?</h5>
            </div>
            <div class="modal-body">
                <select class="form-control" formControlName="reasonForCancel">
                    <option class="placeholder" value="" selected disabled>Select Reason For Cancellation</option>
                    <option value="Some products not available">Some products not available</option>
                    <option value="All products not available">All products not available</option>
                    <option value="Currently shop is closed">Currently shop is closed</option>
                    <option value="Temporarily not available - Call us later">Temporarily not available - Call us later</option>
                </select>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal();">No</button>
                <button type="button" class="btn btn-primary" (click)="orderStatus($event)">Cancel Order</button>
            </div>
            </form>
        </div>
    </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
