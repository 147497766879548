import {AppState} from '@/store/state';
import {Component, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserDetails} from "@pages/modal/userDetails";
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Subject, takeUntil} from 'rxjs';
import Swal from "sweetalert2";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-shop-owner-register',
    templateUrl: './shop-owner-register.component.html',
    styleUrls: ['./shop-owner-register.component.scss']
})
export class ShopOwnerRegisterComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    newShopOwnerDetails: UserDetails | undefined;
    isVisible = false;
    password: string;
    isPasswordVisible: boolean = false;

    constructor(
        private renderer: Renderer2,
        private store: Store<AppState>,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private toaster: ToastrService) {

    }

    shopOwnerDetails = new UntypedFormGroup({
        firstName: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        lastName: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        emailId: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ]),
        phoneNumber: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
        ]),
        userName: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        password: new UntypedFormControl("", [
            Validators.required,
            // Validators.nullValidator,
            Validators.nullValidator, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,10}')
        ]),
        enabled: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        role: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),

    });

    public shopOwnerRegisterErrors = {
        firstName: "",
        lastName: "",
        emailId: "",
        phoneNumber: "",
        userName: "",
        password: "",
        enabled: "",
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.shopOwnerRegisterErrors.firstName = "";
        this.shopOwnerRegisterErrors.lastName = "";
        this.shopOwnerRegisterErrors.emailId = "";
        this.shopOwnerRegisterErrors.phoneNumber = "";
        this.shopOwnerRegisterErrors.userName = "";
        this.shopOwnerRegisterErrors.password = "";
        this.shopOwnerRegisterErrors.enabled = "";
        let hasError = false;

        if (this.shopOwnerDetails.get("firstName")?.invalid) {
            this.shopOwnerRegisterErrors.firstName = "*First Name is Required";
            hasError = true;
        }
        if (this.shopOwnerDetails.get("lastName")?.invalid) {
            this.shopOwnerRegisterErrors.lastName = "*Last Name is Required";
            hasError = true;
        }
        if (this.shopOwnerDetails.get('emailId')?.invalid) {
            if (this.shopOwnerDetails.get('emailId').errors.required) {
                this.shopOwnerRegisterErrors.emailId = "* Email ID is required";
            }
            if (this.shopOwnerDetails.get('emailId').errors.pattern) {
                this.shopOwnerRegisterErrors.emailId = "*Enter Valid Email Address (eg:example@gmail.com)";
            }
            hasError = true;
        }
        if (this.shopOwnerDetails.get('phoneNumber')?.invalid) {
            if (this.shopOwnerDetails.get('phoneNumber').errors.required) {
                this.shopOwnerRegisterErrors.phoneNumber = "*Phone Number is required";
            }
            if (this.shopOwnerDetails.get('phoneNumber').errors.pattern) {
                this.shopOwnerRegisterErrors.phoneNumber = "*Please, Enter 10 digit Valid Phone Number.";
            }
            hasError = true;
        }
        if (this.shopOwnerDetails.get("userName")?.invalid) {
            this.shopOwnerRegisterErrors.userName = "*User Name is Required";
            hasError = true;
        }
        if (this.shopOwnerDetails.get("password")?.invalid) {
            if (this.shopOwnerDetails.get('password').errors.required) {
                this.shopOwnerRegisterErrors.password = "*Password is required";

            }
            if (this.shopOwnerDetails.get('password').errors.pattern) {
                this.shopOwnerRegisterErrors.password = "Your password must be 8-10 characters long and atleast 1-special character, 1-upper case,1-lower case and 1- number are must included";
            }
            hasError = true;
        }
        if (this.shopOwnerDetails.get("enabled")?.invalid) {
            this.shopOwnerRegisterErrors.enabled = "*Please checked the Terms & Condition";
            hasError = true;
        }


        if (!hasError) {
            this.saveShopOwnerDetails();
        }
    }

    //Password show and hide function
    togglePasswordVisibility() {
        const passwordShow = document.getElementById('password') as HTMLInputElement;
        if (passwordShow.type == 'password') {
            passwordShow.type = 'text';
        } else {
            passwordShow.type = 'password';
        }
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    //Navigation to Login Screen
    onNavigation(url: string) {
        if (url && url !== "") {
            this.router.navigate([url]);
            console.log("Navigate login:")
        }
    }

    //Only Enter Number Input box Event
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    // Save Shop Owner Functionality
    saveShopOwnerDetails() {
        this.shopOwnerDetails.controls['role'].setValue("VENDOR");
        this.spinner.show();
        this.appService
            .saveshopOwnDetails(this.shopOwnerDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    setTimeout(() => {
                    }, 2000);
                    console.log("Shop Owner Details::", data);
                    // @ts-ignore
                    this.newShopOwnerDetails = data;
                    this.spinner.hide();
                    this.toaster.success("Registered Successfully");
                    window.location.reload();
                    // this.successAlert();
                },
                (err: any) => {
                    this.spinner.hide();
                    // this.errorAlert();
                    this.toaster.error("Something went wrong, Please try Again")
                },
                () => console.log("HTTP request completed.")
            );
    }

    ngOnInit(): void {
        this.isVisible = true;
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }


}