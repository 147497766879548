<div class="content-header">
    <div class="container-fluid">
       <div class="row mb-2">
          <div class="row">
             <div class="col-md-6">
                <div class="col-1"></div>
                <div class="col-11 five">
                   <h1>Dashboard</h1>
                </div>
             </div>
          </div>
          <!-- /.col -->
          <div class="col-sm-4"></div>
          <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Dashboard v1</li>
             </ol>
          </div>
          <!-- /.col -->
       </div>
       <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
 </div>
 <!-- /.content-header -->
 <!-- Main content -->
 <section class="content">
    <div class="container-fluid">
       <!-- Small boxes (Stat box) -->
       <div class="row">
          <div class="col-lg-3 col-6">
             <!-- small box -->
             <div class="small-box bg-info">
                <div class="inner">
                   <h3>150</h3>
                   <p>New Orders</p>
                </div>
                <div class="icon">
                   <i class="ion ion-bag"></i>
                </div>
                <a href="#" class="small-box-footer"
                   >More info <i class="fas fa-arrow-circle-right"></i
                   ></a>
             </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
             <!-- small box -->
             <div class="small-box bg-success">
                <div class="inner">
                   <h3>53<sup style="font-size: 20px">%</sup></h3>
                   <p>Bounce Rate</p>
                </div>
                <div class="icon">
                   <i class="ion ion-stats-bars"></i>
                </div>
                <a href="#" class="small-box-footer"
                   >More info <i class="fas fa-arrow-circle-right"></i
                   ></a>
             </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
             <!-- small box -->
             <div class="small-box bg-warning">
                <div class="inner">
                   <h3>44</h3>
                   <p>User Registrations</p>
                </div>
                <div class="icon">
                   <i class="ion ion-person-add"></i>
                </div>
                <a href="#" class="small-box-footer"
                   >More info <i class="fas fa-arrow-circle-right"></i
                   ></a>
             </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
             <!-- small box -->
             <div class="small-box bg-danger">
                <div class="inner">
                   <h3>65</h3>
                   <p>Unique Visitors</p>
                </div>
                <div class="icon">
                   <i class="ion ion-pie-graph"></i>
                </div>
                <a href="#" class="small-box-footer"
                   >More info <i class="fas fa-arrow-circle-right"></i
                   ></a>
             </div>
          </div>
          <!-- ./col -->
       </div>
       <!-- /.row -->
    </div>
    <!-- Main row -->
 </section>
 <!-- /.content -->