import { Component, OnInit } from "@angular/core";
import {PrintDataService} from "@services/printDataService";
import {VendorOrderDetails} from "@pages/modal/vendorOrderDetails";
import {OrderDetails} from "@pages/modal/orderDetails";
import { Router } from '@angular/router';
import {AppService} from "@services/app.service";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject,takeUntil} from "rxjs";
import {InvoiceDetails} from "@pages/modal/invoice/InvoiceDetails";
import {Order} from "@pages/modal/order/Order";
import {ToastrService} from "ngx-toastr";
import {add} from "husky/lib";
import {OrderDetailsForInvoice} from "@pages/modal/invoice/OrderDetailsForInvoice";

@Component({
    selector: 'invoice-print',
    templateUrl: './invoice-print.component.html',
    styleUrls: ['./invoice-print.component.scss']


})

export class InVoicePrintComponent implements OnInit{
    destroy$: Subject<boolean> = new Subject<boolean>();
    orderData: VendorOrderDetails[] = [];
    orderDetails: OrderDetails[] = [];
    orderId: number = 0;
    tax: number = 0;
    shopName: string = "";
    address: string = "";
    orderType: string = "";
    customerAddress: string = "";
    totalAmount: number = 0;
    itemDetails: OrderDetails[] = [];
    invoiceDetails: InvoiceDetails[] = [];
    order: Order | undefined;
    doorNo: string;
    streetName: string;
    area: string;
    city: string;
    state: string;
    retailerName: string;
    retailerGST:string;
    traderName:string;
    shopGst:string;
    invoiceCreatedDate: number;
    shippedDate: string;
    orderUID: string;
    invoiceDate: string;
    epochOrderDate: number;
    orderDate: string;
    epochShippedDate: number;
    orderDetailSForInvoice: OrderDetailsForInvoice[] = [];
    totalPayAmount: number = 0;
    roundOffPayableAmount: number = 0;
    sgst: number;
    cgst: number;
    cGstAmount: number;
    sGstAmount: number;
    roundOff: number;
    roundedDiscount: number;
    netRate: number;
    roundoffShow = false;

    constructor(private dataService: PrintDataService, private appService: AppService, private router: Router, private spinner: NgxSpinnerService,
                private toaster: ToastrService) {
    }
    ngOnInit(): void {
        /*if (this.dataService.orderData != undefined || this.dataService.orderDetails != undefined) {
            // @ts-ignore
            this.orderData = this.dataService.orderData
            // @ts-ignore
            this.orderDetails = this.dataService.orderDetails
            console.log("order Data::", this.orderData)
            // @ts-ignore
            for (let order of this.orderData) {
                this.orderId = order.orderId;
                this.shopName = order.shopName;
                this.address = order.shopAddress;
                this.orderType = order.orderType;
                this.totalAmount = order.totalAmount;
                this.tax = order.taxableAmount
                this.customerAddress= order.deliveryAddress;
                for (let item of this.orderDetails) {
                    this.itemDetails.push(item)
                }
            }
        }*/
       this.orderId = Number(localStorage.getItem('orderId'));
       let retailerOrderId = {
           orderId: this.orderId
       }
        this.getProductDetails(retailerOrderId);
    }

    getProductDetails(retailerOrderId) {
        console.log("Order Id::", retailerOrderId);
        this.spinner.show()
            this.appService
                .invoiceDetails(retailerOrderId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data)=> {
                    this.spinner.hide();
                    console.log("Invoice details::", data)
                    this.invoiceDetails = data;
                    let productList: OrderDetailsForInvoice[] = [];
                    for (let invoice of this.invoiceDetails) {
                        this.doorNo = invoice.deliveryAddress.doorNo;
                        this.streetName = invoice.deliveryAddress.streetName;
                        this.area = invoice.deliveryAddress.area;
                        this.city = invoice.deliveryAddress.city;
                        this.state = invoice.deliveryAddress.state;
                        this.traderName = invoice.traderName;
                        this.orderUID = invoice.orderUid;
                        this.retailerName = invoice.retailerName;
                        this.shopGst = invoice.shopGst;
                        this.retailerGST = invoice.retailerGST;
                        this.invoiceCreatedDate = invoice.invoiceCreatedDate;
                        this.epochOrderDate = invoice.orderDate;
                        this.epochShippedDate = invoice.shippedDate;
                        this.totalPayAmount = invoice.netAmount;
                        productList = productList.concat([...invoice.orderDetailsForInvoice]);
                    }
                    this.orderDetailSForInvoice = productList;

                    var salePrice: number[] = [];
                    var productAmount: number[] = [];
                    for (let total of this.orderDetailSForInvoice) {
                        salePrice.push(Number(total.shopProductSrp * total.itemQty));
                        this.cgst = total.cgst;
                        this.sgst = total.sgst;
                        /*this.netRate = total.shopProductSrp * (total.cgst + total.sgst) / 100;*/
                        productAmount.push(total.itemQty * (total.shopProductSrp * (total.cgst + total.sgst) / 100));
                    }
                    let sum: number = salePrice.reduce((acc, currentValue) => acc + currentValue, 0);
                    let productTotalGST: number = productAmount.reduce((acc, currentValue) => acc + currentValue, 0);
                    this.totalAmount = sum;
                    this.cGstAmount = productTotalGST / 2;
                    this.sGstAmount = productTotalGST / 2;
                    this.roundOffPayableAmount = Math.round(this.totalPayAmount);
                    this.roundOff = this.totalPayAmount - this.roundOffPayableAmount ;
                    let formattedNumber: string = this.roundOff.toFixed(1);
                    this.roundedDiscount = parseFloat(formattedNumber);
                    if (this.roundOff == 0.00) {
                        this.roundoffShow = false;
                    }
                    console.log("SRP::", salePrice)
                    console.log("product data::", sum);
                    console.log("CGST::", this.cGstAmount)
                    console.log("SGST::", this.sGstAmount);
                    console.log("round Amount::", this.roundOffPayableAmount);
                    console.log("roundOff::", this.roundOff);
                    console.log("roundDiscount::", this.roundedDiscount);
                    console.log("Net rate::", productAmount)


                    let dateObject: Date = new Date(this.invoiceCreatedDate);
                    let formattedDate: string = dateObject.toLocaleDateString('en-GB');
                    console.log("Formatted Date:", formattedDate);
                    this.invoiceDate = formattedDate;
                    let dateObjectOne: Date = new Date(this.epochOrderDate);
                    let formattedDateOne: string = dateObjectOne.toLocaleDateString('en-GB');
                    console.log("Formatted Date:", formattedDateOne);
                    this.orderDate = formattedDate;
                    let dateObjectTwo: Date = new Date(this.epochShippedDate);
                    let formattedDateTwo: string = dateObjectTwo.toLocaleDateString('en-GB');
                    console.log("Formatted Date:", formattedDateTwo);
                    this.shippedDate = formattedDateTwo;

                    setTimeout(function () {
                        const style = document.createElement('style');
                        style.innerHTML = `
                         @media print {
                         @page {
                         size: A5;
                         }
                         }
                         `;
                        document.head.appendChild(style);
                        window.print();
                        document.head.removeChild(style);
                    }, 3000);
                    setTimeout( ()=> {
                        this.router.navigate(['/main/order-processing'])
                    }, 3000)
                }, (error: any) => {
                    this.spinner.hide();
                    /*this.toaster.error("Something went wrong")*/
                });
    }

}