import {AppState} from '@/store/state';
import {Component, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {allShopProductDetails} from '@pages/modal/allShopProductDetails';
import {shopDetails} from '@pages/modal/shopdetails';
import {AppService} from '@services/app.service';
import {Subject, takeUntil} from 'rxjs';
import {productDetails} from "@pages/modal/productdetails";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {data} from "browserslist";


@Component({
    selector: 'my-shop-product',
    templateUrl: './my-shop-product.component.html',
    styleUrls: ['./my-shop-product.component.scss']
})
export class MyShopProductComponent implements OnInit {
    searchText;
    destroy$: Subject<boolean> = new Subject<boolean>();
    shopList: shopDetails[] = [];
    localShopList: shopDetails[] = [];
    shopProductList: allShopProductDetails[] = [];
    shopBasedProductList: allShopProductDetails[] = [];
    activeShopProducts: allShopProductDetails[] = [];
    productDetails: productDetails[] = [];
    showShopName: String[] = [];
    shopName: string;

    constructor(private renderer: Renderer2, private store: Store<AppState>,
                private appService: AppService, private router: Router,
                private spinner: NgxSpinnerService, private toaster: ToastrService) {
    }

    ngOnInit(): void {
        this.getShopDetails();
    }

    updateButtonShow(product: allShopProductDetails) {
        product.isUpdateEnabled = true;
    }

    // shopId based shopName show in the dropdown
    /*getShopDetails() {
        this.spinner.show();
        this.appService.getShopDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Get Shop Details", data)
                // @ts-ignore
                this.shopList = data;
                this.spinner.hide();
                var shopList: shopDetails[] = [];
                for (var shop of this.shopList) {
                    var userId = localStorage.getItem('userId')
                    if (Number(userId) == shop.createdBy) {
                        shopList.push(shop)
                        console.log("Shop List::", shopList)
                        this.localShopList = shopList
                    }
                    var shopName: String[] = [];
                    for (var name of shopList) {
                        shopName.push(name.shopName)
                    }
                    this.showShopName = shopName;
                }
            }, (error: any) => {
                this.spinner.hide();
                this.toaster.error("Something went wrong for get Shop Details")
            })
    }*/


    getShopDetails() {
        this.spinner.show();
        this.appService.getShopDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                    console.log("Shop Details::", data);
                    this.shopList = data;
                },
                (error: any) => {
                    this.spinner.hide();
                    this.toaster.error("Something went wrong for get Shop Details")
                })
    }


    selectAllProduct(event) {
        this.activeShopProducts = [] ;
        const value = event.target.value;
        this.shopName = value;
        console.log("traderId::", value)
        let traderId: Number[] = [];
        this.getActiveProduct(value)
    }

    getActiveProduct(value) {
        this.appService
            .getShopProductDetails(value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data)=> {
                console.log("Trader ID based product show::", data);
                // @ts-ignore
                this.shopBasedProductList = data;
                console.log("shop based product Data::", this.shopBasedProductList);
                let activeProductList: allShopProductDetails[] = [];
                for (let shop of this.shopBasedProductList) {
                    if (shop.status == "Active") {
                        activeProductList.push(shop)
                    }
                    this.activeShopProducts = activeProductList;
                }
            })
    }

    /*selectAllProduct(event) {
        const value = event.target.value;
        var shopId: Number[] = [];
        if (value != '') {
            for (let shop of this.shopList) {
                if (value == shop.shopId) {
                    shopId.push(shop.shopId)
                    this.spinner.show();
                    this.appService
                        .getShopProductDetails(shopId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((data) => {
                            console.log("Shop Products ::", data);
                            this.spinner.hide();
                            var shopProductList: allShopProductDetails[] = [];
                            var productList: productDetails[] = [];
                            //@ts-ignore
                            this.shopProductList = data;
                            for (let shop of this.shopProductList) {
                                if (shop.status == "Active") {
                                    shopProductList.push(shop)
                                }
                                this.activeShopProducts = shopProductList;
                                /!*for (let name of this.activeShopProducts) {
                                    // @ts-ignore
                                    this.productDetails.push(name.products)
                                    console.log("Prodcut details::", this.productDetails)
                                    this.spinner.hide();
                                    for (let pro of this.productDetails) {
                                        name.productName = pro.productName;
                                        name.shopProductMRP = pro.productMRP;
                                        name.shopProductSRP = pro.productSRP;
                                    }
                                }*!/
                            }
                        })
                }
            }
        }
    }*/

    /*updateSRP(newSRP: number, i: number) {
        this.spinner.show();
        this.activeShopProducts[i].shopProductSRP = Number(newSRP);
        let product = this.activeShopProducts[i];
        // console.log("i::", this.activeShopProducts[i]);
        product.shopProductSRP  = Number(newSRP);
        console.log("update srp::", Number(newSRP));
        this.appService
            .updateProduct(product)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data)=> {
                this.spinner.hide()
                console.log("Update SRP::", data)
            },(error: any) => {
                this.spinner.hide();
                this.toaster.error("Something went wrong please try again later")
            })
    }*/

    updateSRP(productSRP, productMRP, productId) {
        this.spinner.show();
        /*this.activeShopProducts[i].shopProductSRP = Number(newSRP);
        this.activeShopProducts[i].shopProductMRP = Number(newMRP);
        let product = this.activeShopProducts[i];
        // console.log("i::", this.activeShopProducts[i]);
        product.shopProductSRP  = Number(newSRP);
        product.shopProductMRP = Number(newMRP);
        console.log("update srp::", Number(newSRP));
        console.log("update mrp::", Number(newMRP));*/
        console.log("SRP::", productSRP);
        console.log("MRP::", productMRP);
        console.log("productId", productId)
        this.appService
            .updateProduct(productSRP, productMRP, productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data)=> {
                this.spinner.hide()
                this.toaster.success("Rate updated successfully");
                console.log("Update SRP::", data)
                this.getActiveProduct(this.shopName);
            },(error: any) => {
                this.spinner.hide();
                this.toaster.error("Something went wrong please try again later")
            })
    }


}
