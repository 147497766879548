<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
       <div class="row mb-2">
          <div class="col-sm-6">
             <h1>Sub Menu</h1>
          </div>
          <div class="col-sm-6">
             <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Blank Page</li>
             </ol>
          </div>
       </div>
    </div>
    <!-- /.container-fluid -->
 </section>
 <!-- Main content -->
 <section class="content">
    <!-- Default box -->
    <div class="container-fluid">
       <div class="card">
          <div class="card-header">
             <h3 class="card-title">Title</h3>
             <div class="card-tools">
                <button
                   type="button"
                   class="btn btn-tool"
                   data-widget="collapse"
                   data-toggle="tooltip"
                   title="Collapse"
                   >
                <i class="fa fa-minus"></i>
                </button>
                <button
                   type="button"
                   class="btn btn-tool"
                   data-widget="remove"
                   data-toggle="tooltip"
                   title="Remove"
                   >
                <i class="fa fa-times"></i>
                </button>
             </div>
          </div>
          <div class="card-body">
             Start creating your amazing application!
          </div>
          <!-- /.card-body -->
          <div class="card-footer">Footer</div>
          <!-- /.card-footer-->
       </div>
    </div>
    <!-- /.card -->
 </section>
 <!-- /.content -->