<div class="container-fluid">
    <div class="row mb-2">
        <div class="col-md-12">
            <div class="col-1"></div>
            <div class="col-11 five">
                <h1><span><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> Trader Orders</h1>
            </div>
        </div>
    </div>
    <form [formGroup]="orderProcessing" id="orderProcessing">
        <div class="row mt-3">
            <div class="col-md-4">
                <select class="form-control form-control-sm" id="shopName"
                        (change)="selectShopOrders($event)">
                    <option value="" disabled selected>select Shop Name</option>
                    <option *ngFor="let shop of localShopList" value="{{shop.shopId}}">{{shop.shopName}}</option>
                </select>
            </div>
        </div>
    </form>
    <div class="card container-fluid p-2 mt-3">
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="btn-group btn-group-justified">
                    <a class="btn btn-white" (click)="orderPlaced()">ORDER PLACED <span class="badge">{{orderPlacedCount}}</span></a>
                    <a type="button" class="btn btn-white" (click)="orderAccepted()">ORDER ACCEPTED <span class="badge">{{orderAcceptedCount}}</span></a>
                    <a type="button" class="btn btn-white" (click)="orderReady()">READY FOR PICKUP <span class="badge">{{orderPickupCount}}</span></a>
                    <a type="button" class="btn btn-white" (click)="orderClosed()">ORDER CLOSED / CANCELED <span class="badge">{{orderClosedCount}}</span></a>
                </div>
            </div>
        </div>
        <div class="card container bg-light mt-3">
          <div class="row mt-2 mb-2">
              <div class="input-group col-md-9">
                  <input type="search" class="form-control rounded form-control-sm" [(ngModel)]="searchText" placeholder="Search..." aria-label="Search" aria-describedby="search-addon" />
                  <span class="input-group-text bg-light border-0" id="search-addon"><i class="fas fa-search"></i></span>
              </div>
          </div>
        </div>
        <div class="container-fluid" [style.display]="orderPlacedShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md tableFixHead">
                    <table class="table rounded  text-center">
                        <thead class="header rounded sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Order Type</th>
                            <th>Payment Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let orderplace of this.orderPlacedList | filter: searchText; let i = index;" (click)="modalShow(orderplace.orderId);">
                            <td>{{i+1}}</td>
                            <td>{{orderplace.orderId}}</td>
                            <td>RS. {{orderplace.totalAmount}}</td>
                            <td><span class="badge blue-badge"><i class="bi-dot text-blue"></i>{{orderplace.orderStatus}}</span></td>
                            <td>{{orderplace.orderType}}</td>
                            <td>
                                <p *ngIf="orderplace.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">✔ Paid</span>
                                </p>
                                <p *ngIf="orderplace.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">✘Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="container-fluid" [style.display]="orderAcceptedShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md tableFixHead">
                    <table class="table text-center">
                        <thead class="header sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Order Type</th>
                            <th>Payment Status</th>
<!--                            <th>Resend SMS</th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let orderplace of this.orderAcceptedList | filter: searchText; let i = index;" (click)="modalShow(orderplace.orderId);">
                            <td>{{i+1}}</td>
                            <td>{{orderplace.orderId}}</td>
                            <td>RS. {{orderplace.totalAmount}}</td>
                            <td><span class="badge save-badge">{{orderplace.orderStatus}}</span></td>
                            <td>{{orderplace.orderType}}</td>
                            <td>
                                <p *ngIf="orderplace.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">✔ Paid</span>
                                </p>
                                <p *ngIf="orderplace.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">✘Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="container-fluid" [style.display]="orderReadyShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md table-wrapper-scroll-y tableFixHead">
                    <table class="table text-center">
                        <thead class="header sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Order Type</th>
                            <th>Payment Status</th>
<!--                            <th>Resend SMS</th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let orderplace of this.orderPickupList | filter: searchText;let i = index;" (click)="modalShow(orderplace.orderId);">
                            <td>{{i+1}}</td>
                            <td>{{orderplace.orderId}}</td>
                            <td>RS. {{orderplace.totalAmount}}</td>
                            <td><span class="badge yellow-badge">{{orderplace.orderStatus}}</span></td>
                            <td>{{orderplace.orderType}}</td>
                            <td>
                                <p *ngIf="orderplace.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">✔ Paid</span>
                                </p>
                                <p *ngIf="orderplace.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">✘Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="container-fluid" [style.display]="orderCloseShow ? 'block' : 'none'">
            <div class="row mt-3">
                <div class="col-md-12 table-responsive-md tableFixHead">
                    <table class="table  text-center">
                        <thead class="header sticky-top">
                        <tr>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Total Bill Amount</th>
                            <th>Order Status</th>
                            <th>Order Type</th>
                            <th>Payment Status</th>
<!--                            <th>Resend SMS</th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let orderplace of this.orderClosedList | filter: searchText; let i = index;" (click)="modalShow(orderplace.orderId);">
                            <td>{{i+1}}</td>
                            <td>{{orderplace.orderId}}</td>
                            <td>RS. {{orderplace.totalAmount}}</td>
                            <td><span class="badge danger-badge">{{orderplace.orderStatus}}</span></td>
                            <td>{{orderplace.orderType}}</td>
                            <td>
                                <p *ngIf="orderplace.paymentStatus == 'Paid'">
                                    <span class="badge save-badge">✔Paid</span>
                                </p>
                                <p *ngIf="orderplace.paymentStatus == 'Not Paid'">
                                    <span class="badge danger-badge">✘Not Paid</span>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div  [style.display]="isVisible ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="row modal-header">
                <div class="col-md-10 ms-auto">
                    <h5 class="modal-title" id="exampleModalLabel"><b>Order Details : Order ID - {{this.orderId}}</b></h5>
                </div>
                <div class="col-md-1 sm-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                    </svg>
                </div>
                <div class="col-md-1 sm-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16" (click)="closeAlert();">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid bg-primary p-2 sticky-top">
                    <div class="col-md-12 text-center font-weight-bolder">
                        <h5>MY CART</h5>
                    </div>
                </div>
                <div class="container-fluid y-scrollable">
                    <div class="col-md-12 table-responsive-md">
                        <table class="table table-borderless">
                            <thead>
                            <tr>
                                <th class="col-md-9">Item</th>
                                <th class="col-md-1">Price</th>
                                <th class="col-md-1">Quantity</th>
                                <th class="col-md-1">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let order of orderDetails">
                                <td>{{order.itemName}}</td>
                                <td class="text-center">{{order.itemSRP}}</td>
                                <td class="text-center">{{order.itemCount}}</td>
                                <td class="text-center">{{order.totalAmount}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="container-fluid bg-light align-content-center">
                    <div class="col-md-12 text-center font-weight-bolder">
                        <label>Payment Details</label>
                    </div>
                </div>
                <hr>
                <div class="container-fluid">
                    <div class="row mt-3">
                        <div class="col-md-8 sm-auto">
                            <label class="font-weight-bold">Total</label>
                        </div>
                        <div class="col-md-2 sm-auto">
                            <label class="font-weight-light">Rs</label>
                        </div>
                        <div class="col-md-2 sm-auto">
                            <label class="font-weight-bold">{{this.totalAmount}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="font-weight-light">Order Discount</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-light">(%)</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-light">{{discount}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="font-weight-bold">Chargeable Amount</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-light">Rs</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-bold">{{this.chargeableAmount}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="font-weight-light">Tax Amount</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-light">(1%)</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-light">{{this.taxableAmount}}</label>
                        </div>
                    </div>
                    <hr>
                    <div class="row mt-3">
                        <div class="col-md-8">
                            <label class="font-weight-bold">Payable Amount</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-light">Rs</label>
                        </div>
                        <div class="col-md-2">
                            <label class="font-weight-bold">{{this.payableAmount}}</label>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-3">
                            <button type="button" *ngIf="isCanceled" class="btn btn-secondary" data-bs-dismiss="modal" (click)="close()">Cancel</button>
                        </div>
                        <div class="col-md-5">
                            <h3><span class="badge bg-white text-success text-center" [style.Display]="this.isPaid ? 'block' : 'none'">✔ PAID</span></h3>
                            <h3><span class="badge bg-white  text-center text-danger"[style.Display]="this.isNotPaid ? 'block' : 'none'">❌ NOT PAID</span></h3>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="accept btn btn" [style.Display]="this.isAccepted ? 'block' : 'none'" (click)="orderStatus($event)">Accept</button>
                            <button type="button" class="accept btn btn" [style.Display]="this.isReady ? 'block' : 'none'" (click)="orderStatus($event)">Ready For Pickup</button>
                            <button type="button" class="accept btn btn" [style.Display]="this.isClosed ? 'block' : 'none'" (click)="orderStatus($event)">Order Closed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" tabindex="-1" role="dialog" [style.display]="isVisibleCancel ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <form [formGroup]="orderProcessing">
            <div class="modal-header">
                <h5 class="modal-title text-center">Order Cancel Remarks ?</h5>
            </div>
            <div class="modal-body">
                <select class="form-control" formControlName="reasonForCancel">
                    <option class="placeholder" value="" selected disabled>Select Reason For Cancellation</option>
                    <option value="Some products not available">Some products not available</option>
                    <option value="All products not available">All products not available</option>
                    <option value="Currently shop is closed">Currently shop is closed</option>
                    <option value="Temporarily not available - Call us later">Temporarily not available - Call us later</option>
                </select>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal();">No</button>
                <button type="button" class="btn btn-primary" (click)="orderStatus($event)">Cancel Order</button>
            </div>
            </form>
        </div>
    </div>
</div>

<ngx-spinner bdColor = "rgba(255, 255, 255, 1)" size = "large" color = "#FFFFFF"
             [fullScreen] = "true">
    <figure class="loader">
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </figure>
</ngx-spinner>