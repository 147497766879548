import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Config} from "@/store/modal/config";

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private config: Config | undefined;

    constructor(private http: HttpClient) {
    }

    load(url: string) {
        return new Promise((resolve) => {
            this.http.get<Config>(url).pipe(map(res => res))
                .subscribe(config => {
                    console.log("Config::", config);
                    this.config = config;
                    // @ts-ignore
                    resolve();
                });
        });

    }

    getConfig(): Config {
        return this.config!
    }
}
