import {productDetails} from "@pages/modal/productdetails";

export interface allShopProductDetails {
 /*   enabled: boolean;
    shopProductMRP: number;
    shopProductSRP: number;
    shopProductTax: number;
    shopProductDiscountType: number;
    shopMinOrderQty: number;
    shopMaxOrderQty: number;
    status: string;
    mainProductCategory: number;
    subProductCategory: number;
    products: productDetails;
    shopId: number;
    productName: string;
    traderId: number;
    distributor: number;*/

    shopProductId: number;
    enabled: boolean;
    shopProductMRP: number;
    shopProductSRP: number;
    shopProductTax: number;
    shopProductDiscountType: number;
    shopMinOrderQty: number;
    shopMaxOrderQty: number;
    status:string;
    mainProductCategoryId: number;
    subProductCategoryId: number;
    productsId: number;
    productName: string;
    traderId: number;
    distributorId: number;
    itemCount: number;
    distributor_name: string;
    isUpdateEnabled: boolean;
    sgst: number;
    cgst: number;
}
export class allShopProductDetailsData {
    /*   enabled: boolean;
       shopProductMRP: number;
       shopProductSRP: number;
       shopProductTax: number;
       shopProductDiscountType: number;
       shopMinOrderQty: number;
       shopMaxOrderQty: number;
       status: string;
       mainProductCategory: number;
       subProductCategory: number;
       products: productDetails;
       shopId: number;
       productName: string;
       traderId: number;
       distributor: number;*/

    shopProductId: number;
    enabled: boolean;
    shopProductMRP: number;
    shopProductSRP: number;
    shopProductTax: number;
    shopProductDiscountType: number;
    shopMinOrderQty: number;
    shopMaxOrderQty: number;
    status:string;
    mainProductCategoryId: number;
    subProductCategoryId: number;
    productsId: number;
    productName: string;
    traderId: number;
    distributorId: number;
    itemCount: number;
    distributor_name: string;
    sgst: number;
    cgst: number;
    appCode: string;
}
