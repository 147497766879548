<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <div class="row">
            <div class="col-md-12">
                <img src="./assets/img/mykadainew.png" style="height:80px;width:80px;">
            </div>
        </div>
    </div>
    <div class="card-body">
        <p class="login-box-msg"><b>WELCOME</b></p>
        <p class="login-box-msg"><b>LOGIN TO CONTINUE</b></p>

        <form [formGroup]="loginForm" id="loginForm" (ngSubmit)="onSubmit()">
            <div class="input-group mb-3">
                <input
                    formControlName="userName" id="userName" [(ngModel)]="userName"
                    type="text"
                    class="form-control"
                    placeholder="User Name"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-user"></span>
                    </div>
                </div>
            </div>
            <span *ngIf="loginErrors.userName" class="error-text">{{loginErrors.userName}}</span>
            <div class="input-group mb-3">
                <input
                    formControlName="password" id="password" [(ngModel)]="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"minlength="8" maxlength="15"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <i class="toggle-password fa fa-lock-open" data-toggle="tooltip" data-placement="top" title="Click to Show Password" (click)="togglePasswordVisibility()" [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <span *ngIf="loginErrors.password" class="error-text">{{loginErrors.password}}</span>
            <div class="row">
                <!--<div class="col-7">
                    <div class="icheck-primary">
                        <input type="checkbox" id="rememberMe" formControlName="rememberMe" [(ngModel)]="rememberMe"/>
                        <label for="rememberMe" style="font-family: 'Times New Roman'"> Remember Me </label>
                    </div>
                </div>-->
                <!-- /.col -->
                <div class="col-md-12 btn-block mb-3">
                    <!--<pf-button
                        type="submit"
                        [block]="true"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                    ><span class="font-weight-bolder">LOG IN</span></pf-button>-->
                    <button type="submit" class="btn btn-primary btn-block">Log In</button>
                </div>
            </div>
            <!-- <div class="social-auth-links text-center mb-3">
                <span class="mb-1">- or -</span>
                <pf-button
                        class="mb-2"
                        [block]="true"
                        [loading]="isFacebookLoading"
                        [disabled]="isAuthLoading || isGoogleLoading"
                        (click)="loginByFacebook()"
                >
                    <i class="fab fa-facebook mr-2"></i>
                    Sign in using Facebook
                </pf-button>
                <pf-button
                        [block]="true"
                        [loading]="isGoogleLoading"
                        [disabled]="isAuthLoading || isFacebookLoading"
                        theme="danger"
                        (click)="loginByGoogle()"
                >
                    <i class="fab fa-google mr-2"></i>
                    Sign in using Google+
                </pf-button>
            </div> -->
        </form>

        <!--<p class="mb-1">
            <a [routerLink]="['/forgot-password']">I forgot my password</a>
        </p>-->
<!--        <p class="mb-0">-->
<!--            <a [routerLink]="['/shop-owner-register']" class="text-center">-->
<!--                Register a New Membership-->
<!--            </a>-->
<!--        </p>-->
        <!-- <p class="mb-1">
            <a [routerLink]="['/shopownerregister']">Register Shop Owner</a>
        </p> -->
        <!-- <div class="row mt-3">
            <div class="col-md-12 text-center">
                <label>Not a Member?</label>
             <label class="text-align" type="button" (click)="onNavigation('shop-owner-register')">Register</label>
            </div>
        </div> -->
        <!--<div class="text-center">
            <p class="text-center text-muted mt-2 mb-0">Not a Member? <a class="text-align" type="button" data-toggle="tooltip" data-placement="top" title="Click to start your Registration" (click)="onNavigation('shop-owner-register')">Register</a></p>
        </div>-->
    </div>
    <!-- /.login-card-body -->
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
