import {Component, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {Router} from "@angular/router";
import {shopDetails} from "@pages/modal/shopdetails";
import {VendorOrderDetails} from "@pages/modal/vendorOrderDetails";
import {AppService} from "@services/app.service";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";
import Swal from "sweetalert2";
import {PrintDataService} from "@services/printDataService";
import {OrderDetails} from "@pages/modal/orderDetails";
import {allShopProductDetails} from "@pages/modal/allShopProductDetails";
import {Ng2SearchPipeModule} from "ng2-search-filter";


@Component({
    selector: 'trader-order',
    templateUrl: './trader-order-processing.component.html',
    styleUrls: ['./trader-order-processing.component.scss']
})

export class TraderOrderProcessingComponent implements OnInit {
    searchText;
    destroy$: Subject<boolean> = new Subject<boolean>();
    shopId: number = 0;
    showShopName: String[] = [];
    orderList: VendorOrderDetails[] = [];
    orderDetails: OrderDetails[] = [];
    itemsDetails: allShopProductDetails[] = [];
    items: String[] = [];
    orderPlacedList: VendorOrderDetails[] = [];
    orderAcceptedList: VendorOrderDetails[] = [];
    orderPickupList: VendorOrderDetails[] = [];
    orderClosedList: VendorOrderDetails[] = [];
    modalShowData: VendorOrderDetails[] = [];
    orderItemList: VendorOrderDetails[] = [];
    orderId: string = "";
    currentOrderId: number = 0;
    totalAmount: string = "";
    discount: string = "";
    taxableAmount: string = "";
    chargeableAmount: string = "";
    payableAmount: string = "";
    shopList: shopDetails[] = [];
    localShopList: shopDetails[] = [];
    orderPlacedCount: number = 0;
    orderAcceptedCount: number = 0;
    orderPickupCount: number = 0;
    orderClosedCount: number = 0;
    isVisible = false;
    isVisibleCancel = false;
    orderPlacedShow = true;
    orderAcceptedShow = false;
    orderReadyShow = false;
    orderCloseShow = false;
    isAccepted = false;
    isReady = false;
    isClosed = false;
    isClosedStatus = false;
    isCanceled = false;
    isCancel = false;
    isPaid = false;
    isNotPaid = false;

    constructor(
        private router: Router, private appService: AppService,
        private spinner: NgxSpinnerService, private toaster: ToastrService,
        private dataService: PrintDataService
    ) {
    }

    orderProcessing = new UntypedFormGroup({
        orderStatus: new UntypedFormControl("", Validators.required),
        reasonForCancel: new UntypedFormControl("", Validators.required),
        orderId: new UntypedFormControl("", Validators.required)
    });

    ngOnInit(): void {
        /*this.getShopDetails();*/
    }

    // New order based notification sound alert
    orderNotification() {
        let audio = new Audio();
        audio.src = "assets/audio/Waterdrop_Drop.mp3"
        audio.load();
        audio.play();
    }

    close() {
        this.isVisibleCancel = true;
    }

    closeModal() {
        this.isVisibleCancel = false;
    }

    closeAlert() {
        this.isVisible = false;
    }

    // cart modal show based on OrderID
    modalShow(orderId) {
        var orderItemList: VendorOrderDetails[] = [];
        this.modalShowData = [];
        this.currentOrderId = orderId;
        console.log("current Order Id::", this.currentOrderId)
        for (let data of this.orderList) {
            if (data.orderId == orderId) {
                this.modalShowData.push(data)
                this.isVisible = true;
                for (let modal of this.modalShowData) {
                    this.orderId = String(modal.orderId)
                    this.totalAmount = String(modal.totalAmount)
                    for (let item of this.orderDetails) {
                        this.discount = String(item.itemExtraDiscount)
                    }
                    this.chargeableAmount = String(modal.chargeableAmount)
                    this.taxableAmount = String(modal.taxableAmount)
                    this.payableAmount = String(modal.payableAmount)
                    if (modal.orderStatus == "Ordered") {
                        this.isAccepted = true;
                        this.isReady = false;
                        this.isClosed = false;
                        this.isCanceled = true;
                    }
                    if (modal.orderStatus == "Accepted") {
                        this.isAccepted = false;
                        this.isReady = true;
                        this.isClosed = false;
                        this.isCanceled = true;
                    }
                    if (modal.orderStatus == "Ready") {
                        this.isAccepted = false;
                        this.isReady = false;
                        this.isClosed = true;
                        this.isCanceled = true;
                    }
                    if (modal.orderStatus == "Closed") {
                        this.isAccepted = false;
                        this.isReady = false;
                        this.isCanceled = false;
                        this.isClosed = false;
                    }
                    if (modal.orderStatus == "Cancelled") {
                        this.isAccepted = false;
                        this.isReady = false;
                        this.isCanceled = false;
                        this.isClosed = false;
                    }
                    if (modal.paymentStatus == "Paid") {
                        this.isPaid = true;
                        this.isNotPaid = false;
                    }
                    if (modal.paymentStatus == "Not Paid") {
                        this.isPaid = false;
                        this.isNotPaid = true;
                    }
                    var shopName: string[] = [];
                    var shopAddress: string[] = [];
                    for (let shop of this.localShopList) {
                        if (modal.shopId == shop.shopId) {
                            shopName.push(shop.shopName)
                            shopAddress.push(shop.streetAddress + ", " + shop.area + ", " + shop.city + ", " + shop.postalCode)
                            modal.shopName = String(shopName)
                            modal.shopAddress = String(shopAddress)
                        }
                    }
                }
                // @ts-ignore
                this.dataService.orderData = this.modalShowData;
                // @ts-ignore
                this.dataService.orderDetails = this.orderDetails;
                console.log("dataService data::", this.dataService.orderData)
            }
        }
    }

    orderPlaced() {
        this.orderPlacedShow = true;
        this.orderAcceptedShow = false;
        this.orderReadyShow = false;
        this.orderCloseShow = false;
    }

    orderAccepted() {
        console.log("Hide", this.orderPlacedShow)
        this.orderAcceptedShow = true;
        this.orderReadyShow = false;
        this.orderCloseShow = false;
        this.orderPlacedShow = false;
    }

    orderReady() {
        this.orderReadyShow = true;
        this.orderCloseShow = false;
        this.orderAcceptedShow = false;
        this.orderPlacedShow = false;
    }

    orderClosed() {
        this.orderCloseShow = true;
        this.orderReadyShow = false;
        this.orderAcceptedShow = false;
        this.orderPlacedShow = false;
    }

    orderStatus(event: any) {
        const Buttonstatus = event.target.innerText;
        console.log("Button Status", Buttonstatus);
        if (Buttonstatus == "Accept") {
            for (let modal of this.modalShowData) {
                if (modal.paymentStatus == "Not Paid") {
                    this.paymentAlertForAccept();
                } else if (modal.paymentStatus == "Paid") {
                    this.orderProcessing.controls["orderId"].setValue(this.currentOrderId)
                    this.orderProcessing.controls["orderStatus"].setValue("Accepted")
                    this.updateOrderStatus();
                }
            }
        } else if (Buttonstatus == "Ready For Pickup") {
            this.orderProcessing.controls["orderId"].setValue(this.currentOrderId)
            this.orderProcessing.controls["orderStatus"].setValue("Ready")
            this.updateOrderStatus();
        } else if (Buttonstatus == "Order Closed") {
            for (let modal of this.modalShowData) {
                if (modal.paymentStatus == "Not Paid") {
                    this.payAlert();
                } else if (modal.paymentStatus == "Paid") {
                    this.orderProcessing.controls["orderId"].setValue(this.currentOrderId)
                    this.orderProcessing.controls["orderStatus"].setValue("Closed")
                    this.updateOrderStatus();
                    this.printAlert();
                }
            }
        } else if (Buttonstatus == "Cancel Order") {
            this.orderProcessing.controls["orderId"].setValue(this.currentOrderId)
            this.orderProcessing.controls["orderStatus"].setValue("Cancelled")
            this.updateOrderStatus();
        }
    }

    //When  payment is Not Paid  Show payment alert in the accept table
    paymentAlertForAccept() {
        Swal.fire({
            title: 'Payment Not Completed. Do You Want Proceed For COD ?',
            showDenyButton: true,
            confirmButtonText: 'OK',
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.orderProcessing.controls["orderId"].setValue(this.currentOrderId)
                this.orderProcessing.controls["orderStatus"].setValue("Accepted")
                this.updateOrderStatus();
            }
        })
    }

    //payment alert for order closed table
    payAlert() {
        Swal.fire({
            title: 'Payment Not Completed. Do You Want Proceed For COD ?',
            showDenyButton: true,
            confirmButtonText: 'YES',
            denyButtonText: `NO`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.orderProcessing.controls["orderId"].setValue(this.currentOrderId)
                this.orderProcessing.controls["orderStatus"].setValue("Closed")
                this.updateOrderStatus();
                this.printAlert();
            }
        })
    }

    // Invoice print alert based on orderID
    printAlert() {
        Swal.fire({
            title: 'Do You Want Take This Invoice to Print?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'YES',
            denyButtonText: `NO`,
        }).then((result) => {
            if (result.isConfirmed) {
                this.router.navigate(["invoice-print"]);
                setTimeout(function () {
                    window.print();
                }, 3000);
                setTimeout(() => {
                    this.router.navigate(["/main/order-processing"]);
                }, 5000);
            }
        })
    }

    // shop details based on the userID
    getShopDetails() {
        this.spinner.show();
        this.appService.getShopDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Get Shop Details", data)
                // @ts-ignore
                this.shopList = data;
                this.spinner.hide();
                var shopList: shopDetails[] = [];
                for (var shop of this.shopList) {
                    var userId = localStorage.getItem('userId')
                    if (Number(userId) == shop.createdBy) {
                        shopList.push(shop)
                        console.log("Shop List::", shopList)
                        this.localShopList = shopList
                        this.spinner.hide();
                    }
                    var shopName: String[] = [];
                    for (var name of shopList) {
                        shopName.push(name.shopName)
                    }
                    this.showShopName = shopName;
                }
            }, (error: any) => {
                this.spinner.hide();
                this.toaster.error("Something went wrong for get Shop Details")
            })
    }

    selectShopOrders(event) {
        const value = event.target.value;
        var shopId: number[] = [];
        for (let shop of this.shopList) {
            if (value != "") {
                if (value == shop.shopId) {
                    shopId.push(shop.shopId)
                }
            }
            this.shopId = shopId[0];
        }
        this.getOrderData();
    }

    getOrderData() {
        this.spinner.show();
        this.appService.getVendorOrders(this.shopId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Order Details::", data)
                //@ts-ignore
                this.orderList = data;
                for (let order of this.orderList) {
                    this.orderDetails = order.listOrderDetails
                    for (let item of this.orderDetails) {
                        console.log("item name::", item.itemName)
                        // @ts-ignore
                        this.itemsDetails.push(item.shopProductID);
                    }
                }
                this.tableData();
            })
    }

    tableData() {
        var ordersPlaceList: VendorOrderDetails[] = [];
        var ordersAcceptList: VendorOrderDetails[] = [];
        var ordersReadyList: VendorOrderDetails[] = [];
        var ordersCloseList: VendorOrderDetails[] = [];
        for (let order of this.orderList) {
            if (order.orderStatus == "Ordered") {
                ordersPlaceList.push(order)
            }
            this.orderPlacedList = ordersPlaceList.sort((a, b) => b.orderId - a.orderId);
            console.log("order Placed list::", this.orderPlacedList)
            this.orderPlacedCount = this.orderPlacedList.length;

            if (order.orderStatus == "Accepted") {
                ordersAcceptList.push(order)
            }
            this.orderAcceptedList = ordersAcceptList.sort((a, b) => b.orderId - a.orderId);
            console.log("order Accepted list::", this.orderAcceptedList)
            this.orderAcceptedCount = this.orderAcceptedList.length;

            if (order.orderStatus == "Ready") {
                ordersReadyList.push(order)
            }
            this.orderPickupList = ordersReadyList.sort((a, b) => b.orderId - a.orderId);
            console.log("order Pickup list::", this.orderPickupList)
            this.orderPickupCount = this.orderPickupList.length;

            if (order.orderStatus == "Closed" || order.orderStatus == "Cancelled") {
                ordersCloseList.push(order)
            }
            this.orderClosedList = ordersCloseList.sort((a, b) => b.orderId - a.orderId);
            console.log("order Closed list::", this.orderClosedList)
            this.orderClosedCount = this.orderClosedList.length;
        }
        this.spinner.hide();
        const orderCount = localStorage.getItem('Order Count');
        // @ts-ignore
        if (orderCount < this.orderPlacedCount) {
            this.orderNotification();
        }
        localStorage.removeItem('Order Count')
        localStorage.setItem('Order Count', String(this.orderPlacedCount))
    }

    updateOrderStatus() {
        console.log("Update Order Status::", this.orderProcessing.value)
        this.spinner.show();
        this.appService
            .updateOrderStatus(this.orderProcessing.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Update Order Status::", data)
                this.isVisibleCancel = false;
                this.spinner.hide();
                this.toaster.success("Order Status Updated Successfully")
                this.getOrderData();
                this.isVisible = false;
            }, (error: any) => {
                this.isVisibleCancel = false;
                this.spinner.hide();
                this.toaster.error("Something went wrong, Please try Again")
            })

    }


}