<div class="container-fluid>">
    <div class="row">
       <div class="col-md-12">
          <div class="col-1"></div>
          <div class="col-11 five">
             <h1>Shop Approval</h1>
          </div>
       </div>
    </div>
    <hr>
    <div class="col-md-12 table-responsive-md">
       <table class="table table-bordered">
          <thead style="background-color: rgb(249, 204, 78); text-align: center;">
             <tr>
                <th>Shop Id</th>
                <th>Shop Name</th>
                <th>Address</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Shop Active/InActive</th>
                <th></th>
             </tr>
          </thead>
          <tbody>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
          </tbody>
       </table>
    </div>
 </div>